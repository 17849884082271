import React from "react";

// Chakra imports
import {
  Box,
  Icon,
  Flex,
  Text,
  useColorModeValue,
  Image,
  Link,
  SimpleGrid
} from "@chakra-ui/react";
import {
  MdPortrait,
  MdViewList,
  MdContentCopy,
} from "react-icons/md";
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { BsFillPersonFill } from "react-icons/bs";
import Media2 from "components/card/Media2.js";
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import Tooltip from "components/tooltip/Tooltip.js";

export default function Influencer(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const { toggleModal, setModalMedia, instagram_username, follower_count, advocates, statu, multi_campaign,
    multi_brand, campaign_count, media_count, impression, video_views, reach, engagement, link_clicks,
    setVideoModalHeader, setVideoModalUser, setVideoModalDate, setVideoModalHashtags, influencer_id, profil_picture_url,influencerCpm } = props;
  const [content, setContent] = React.useState([]);
  
  React.useEffect(() => {
    const getContentByInfluencerId = async () => {
      try {
        const content = await fetchUtilViaGet('/contentByInfluencerId?influencer_id=' + influencer_id);
        setContent(content);
      } catch (error) {
        console.error(error);
      }
    };
    getContentByInfluencerId();
  }, [influencer_id]);

  function formatNumber(number) {
    if (number >= 1e9) {
      // Billion or more
      return " " + (number / 1e9).toFixed(1) + " B";
    } else if (number >= 1e6) {
      // Million or more
      return " " + (number / 1e6).toFixed(1) + " M";
    } else if (number >= 1e3) {
      // Thousand or more
      return " " + (number / 1e3).toFixed(1) + " K";
    } else if (number == null) {
      return "0";
    } else {
      return " " + number;
    }
  }

  function formatCost(cost) {
    if (cost > 0) {
      return " " + cost.toFixed(2) ;
    } else if (cost == null) {
      return " 0";
    } else {
      return " 0";
    }
  }

  return (
    <Flex
      px='22px'
      pb='10px'
      pt='10px'
      mb='10px'
      backgroundColor='white'
      borderRadius={10}
      flexDirection="column" >
      <Flex>
        {profil_picture_url == '' ?
          <Icon w='32px' h='32px' as={BsFillPersonFill} color={"blue"} />
          :
          <Image
            src={profil_picture_url}
            width={10}
            crossOrigin="anonymous"
            borderRadius='10px'
          />
        }

        <Text paddingLeft='10px' color={textColor} fontSize={{ base: "sm", md: "sm", sm: "x-small" }} fontWeight='700' pr="5px">
          @ {instagram_username}
          <Link target='_blank' href={"https://www.instagram.com/" + instagram_username}>
            <Text color='white' backgroundColor='rgba(223,36,76,0.8)' borderRadius={8} px={"5px"} fontSize={{ base: "sm", md: "sm", sm: "x-small" }} fontWeight='500' maxW={"80px"} minW={"80px"} py={"2px"} display="flex" alignItems="center">
              <Icon w='17px' h='17px' as={AiOutlineInstagram} paddingRight='2px' color={"black"} />
              {formatNumber(follower_count)}
            </Text>
          </Link>
        </Text>
        <Flex
          marginLeft='auto'
          marginRight='10px'
          fontSize={{ base: "sm", md: "sm", sm: "xx-small" }}>
          {multi_campaign == 'true' && (
            <Text color='black' borderColor='rgba(40,85,132, 1)' borderWidth={0.7} borderRadius={5} paddingLeft='3' paddingRight='3' fontWeight='500' py={"3px"} marginRight='5px' maxH={"30px"}>
              MULTI-CAMPAIGN
            </Text>)}
          {multi_brand == 'true' && (
            <Text color='black' borderColor='rgba(40,85,132, 1)' borderWidth={0.7} borderRadius={5} paddingLeft='3' paddingRight='3' fontWeight='500' py={"3px"} marginRight='5px' maxH={"30px"}>
              MULTI-BRAND
            </Text>)}
          <Text color='black' borderColor='rgba(40,85,132, 1)' borderWidth={0.7} borderRadius={5} paddingLeft='3' paddingRight='3' fontWeight='500' py={"3px"} marginRight='5px' maxH={"30px"}>
            {statu == '1' ? "ACTIVE" : "INACTIVE"}
          </Text>
          {advocates == '1' && (
            <Text color='black' borderColor='rgba(40,85,132, 1)' borderWidth={0.7} borderRadius={5} paddingLeft='3' paddingRight='3' fontWeight='500' py={"3px"} maxH={"30px"}>
              ADVOCATE
            </Text>)}
        </Flex>
      </Flex>
      <Flex
        overflowX="scroll"
        width="100%"
        pt='10px'
        pb='5px'>
        {content && content.map((row, index) => (
          <Box key={index} marginRight="0px">
            <Media2 image={row.thumbnail_url != null ? row.thumbnail_url : row.url}
              toggleModal={toggleModal}
              row={row}
              setModalMedia={setModalMedia}
              setVideoModalHeader={setVideoModalHeader}
              setVideoModalUser={setVideoModalUser}
              setVideoModalDate={setVideoModalDate}
              setVideoModalHashtags={setVideoModalHashtags}
            />
          </Box>
        ))}
      </Flex>

      <Flex
        pb='5px'>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          width={"100%"}
          fontSize={{ base: "sm", md: "2xl", lg: "2xl" }}
          >
          <Flex pb={"5px"}>
            <Flex alignItems="flex-end">
              <Icon w='25px' h='25px' as={MdViewList} paddingRight='2px' color={brandColor} />
              <Text color={textColor} fontWeight='700' textAlign="center">
                {campaign_count}
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' alignItems="center">
                  Campaigns
                </Text>
              </Text>
            </Flex>
            <Flex alignItems="flex-end" pl='30px'>
              <Icon w='25px' h='25px' as={MdContentCopy} paddingRight='2px' color={brandColor} />
              <Text color={textColor} fontWeight='700' textAlign="center">
                {media_count}
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' alignItems="center">
                  Content
                </Text>
              </Text>
            </Flex>
          </Flex>
          {/* <Flex alignItems="flex-end" pl='10px'>
              <Icon w='25px' h='25px' as={MdEditDocument} paddingRight='2px' color={brandColor} />
              <Text color={textColor} fontSize='2xl' fontWeight='700' textAlign="center">  
                ?
                <Text color={textColor} fontSize='sm' fontWeight='500'  alignItems="center">              
                Insight
                </Text>
              </Text>
            </Flex>      */}
          <Flex
            minW={500}
            justifyContent={{ base: "left", md: "right" }}
            ml={{base: '-50px', sm: "0px", md: '-80px'}}
            >
            <Text color={textColor} fontWeight='700' textAlign="center">
              <Tooltip text={impression}>
                {formatNumber(impression)}
              </Tooltip>                  
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                Impressions
              </Text>
            </Text>
            <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
              <Tooltip text={video_views}>
                {formatNumber(video_views)}
              </Tooltip>                                
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                Video Views
              </Text>
            </Text>
            <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">              
              <Tooltip text={reach}>
                {formatNumber(reach)}
              </Tooltip>                   
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                Reach
              </Text>
            </Text>
            <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
              <Tooltip text={engagement}>
                {formatNumber(engagement)}
              </Tooltip>                                 
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                Engagements
              </Text>
            </Text>
            <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
              <Tooltip text={link_clicks}>
                {formatNumber(link_clicks)}
              </Tooltip>                                 
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                Link Visit
              </Text>
            </Text>
            {/* <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
              {formatCost(influencerCpm)}
              <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                CPM
              </Text>
            </Text> */}
          </Flex>
        </SimpleGrid>
      </Flex>

    </Flex>
  );
}
