import {
    Flex,
    Icon,
    Text,
    Button,
    Checkbox,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import Modal from 'react-modal';
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";

const customStyles = {
    content: {
        margin: 'auto',
        width: '70%',
        maxHeight: '60%',
        borderRadius: '10px',
        padding: '50px',
        overflowY: 'auto',
    },
};

export default function AddUser(props) {
    const { modal, closeModal } = props;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");

    const [show, setShow] = React.useState(false);

    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}            
            preventScroll={false}>            
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Flex
                        direction='row'>
                        <Heading color={textColor} fontSize='36px' mb='10px'>
                            Add User
                        </Heading>
                        <Button                      
                            h='35px'
                            ml={700}
                            mt={-30}
                            onClick={closeModal}>
                            <Icon as={MdCancel} w={5} h={5}/>
                        </Button>
                    </Flex>                                        
                </Box>
                <Flex
                    direction='row'>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}          >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Email<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='email'
                                placeholder='mail@infdash.com'
                                mb='24px'
                                fontWeight='500'
                                size='lg'

                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder='Min. 8 characters'
                                    mb='24px'
                                    size='lg'
                                    type={show ? "text" : "password"}
                                    variant='auth'

                                />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: "pointer" }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}

                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl display='flex' alignItems='center'>
                            <Checkbox
                                id='remember-login'
                                colorScheme='brandScheme'
                                me='10px'
                            />
                            <FormLabel
                                htmlFor='remember-login'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                Admin
                            </FormLabel>
                            <Checkbox
                                id='remember-login'
                                colorScheme='brandScheme'
                                me='10px'
                                checked
                            />
                            <FormLabel
                                htmlFor='remember-login'
                                mb='0'
                                fontWeight='normal'
                                color={textColor}
                                fontSize='sm'>
                                Member
                            </FormLabel>
                        </FormControl>
                    </Flex>
                    <Flex mr={10}></Flex>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder='Jack'
                                mb='24px'
                                fontWeight='500'
                                size='lg'

                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Surname<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder='Reacher'
                                    mb='24px'
                                    size='lg'
                                    type={"text"}
                                    variant='auth'

                                />

                            </InputGroup>
                        </FormControl>
                    </Flex>
                </Flex>
                <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='30%'
                    h='50'
                    mb='24px'
                    left='auto'
                    right='-70%'>
                    Add
                </Button>
            </Flex>
        </Modal>
    );
}
