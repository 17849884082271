import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,  
} from "@chakra-ui/react";

export default function InfluencerOverview(props) {
  const { influencer_count, no_media, active, ended, multi_campaign_count, multi_brand_count, advocates, updateFilterMode } = props;
  const blueTextColor = "rgba(40, 85, 132, 1)"
  const grayTextColor = useColorModeValue("secondaryGray.900", "white");
  const [influencerTextColor, setInfluencerTextColor] = React.useState(blueTextColor);
  const [noContentTextColor, setNoContentTextColor] = React.useState(grayTextColor);
  const [activeTextColor, setActiveTextColor] = React.useState(grayTextColor);
  const [endedTextColor, setEndedTextColor] = React.useState(grayTextColor);
  const [multiCampTextColor, setMultiCampTextColor] = React.useState(grayTextColor);
  const [multiBrandTextColor, setMultiBrandTextColor] = React.useState(grayTextColor);
  const [advocatesTextColor, setAdvocatesTextColor] = React.useState(grayTextColor);

  function filterModeToInfluencers() {
    setInfluencerTextColor(blueTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(0)    
  }

  function filterModeToNoContent() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(blueTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(1)    
  }

  function filterModeToActiveInfluencer() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(blueTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(2)    
  }

  function filterModeToInActiveInfluencer() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(blueTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(3)    
  }

  function filterModeToMultiCampaignInfluencer() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(blueTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(4)    
  }

  function filterModeToMultiBrandInfluencer() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(blueTextColor);
    setAdvocatesTextColor(grayTextColor);
    updateFilterMode(5)    
  }

  function filterModeToAdvocatesInfluencer() {
    setInfluencerTextColor(grayTextColor);
    setNoContentTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setEndedTextColor(grayTextColor);
    setMultiCampTextColor(grayTextColor);
    setMultiBrandTextColor(grayTextColor);
    setAdvocatesTextColor(blueTextColor);
    updateFilterMode(6)    
  }

  return (
    <Flex        
        px='22px'        
        pt='10px' 
        mb={"10px"}               
        backgroundColor='white'        
        borderRadius={10}                
        >
        <SimpleGrid columns={{ base: 7, sm: 4, md: 7, lg: 7 }} gap={"10px"} >
          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToInfluencers}
          mb='10px'
          >
            <Text color={influencerTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {influencer_count}
              <Text color={influencerTextColor} fontSize={{base: 'x-small', md: "sm", lg: "sm"}} fontWeight='400'>
                INFLUENCERS
              </Text>
            </Text>
          </Button>

          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToNoContent}
          mb='10px'          
          >
            <Text color={noContentTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {no_media}
              <Text color={noContentTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                NO CONTENT
              </Text>
            </Text>  
          </Button>

          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToActiveInfluencer}
          mb='10px'
          >
            <Text color={activeTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {active}
              <Text color={activeTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                ACTIVE
              </Text>
            </Text>   
          </Button>          

          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToMultiBrandInfluencer}
          mb='10px'
          >
            <Text  color={multiBrandTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {multi_brand_count}
              <Text color={multiBrandTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                MULTI-BRAND
              </Text>
            </Text>   
          </Button>


          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToAdvocatesInfluencer}
          mb='10px'
          >
            <Text  color={advocatesTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {advocates}
              <Text color={advocatesTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                ADVOCATES
              </Text>
          </Text>  
          </Button>

          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToInActiveInfluencer}
          mb='10px'
          >
            <Text color={endedTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {ended}
              <Text color={endedTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                ENDED
              </Text>
            </Text>   
          </Button>

          <Button
          variant='no-hover'
          bg='transparent'
          p='0px'
          minW='unset'
          minH='unset'
          
          _focus={{ boxShadow: 'none' }}        
          onClick={filterModeToMultiCampaignInfluencer}
          mb='10px'
          >
            <Text color={multiCampTextColor} fontSize={{ base: "2xl", sm: "xl", md: "2xl", lg: "2xl" }} fontWeight='700' align={{ base: "center", md: "center" }}>
              {multi_campaign_count}
              <Text color={multiCampTextColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='400'>
                MULTI-CAMPAIGN
              </Text>
            </Text>  
          </Button>

        </SimpleGrid>        
      </Flex>
  );
}
