/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,  
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics2";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdYoutubeSearchedFor,
  MdAttachMoney,
  MdCampaign,
  MdFileCopy,
} from "react-icons/md";
import { fetchUtilViaGet } from '../../../components/fetch/FetchUtil.js';
import { Redirect, NavLink } from 'react-router-dom';
import CircleLoader from "react-spinners/CircleLoader";
import Content from "views/super/content/components/Content";
import {columnsDataComplex,} from "views/super/content/variables/columnsData";
import tableDataComplex from "views/super/content/variables/tableDataComplex.json";
const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function User(props) {  
  let [loading, setLoading] = React.useState(false);  
  const [content, setContent] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const getContent = async () => {			
    try {        
      const response = await fetchUtilViaGet('/content?user_id=' + localStorage.getItem('user_id'));           
      setContent(response);     
    } catch (error) {
      console.error(error);
    }
  };        

  React.useEffect(() => {    
    getContent();   
  }, []);

  function refreshContent() {
    setRefresh(true);
  }

  React.useEffect(() => {    
    if (refresh) {     
      getContent();   
      setRefresh(false); 
    }    
  }, [refresh]);

  return (    
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>                   
      {!localStorage.getItem('authenticated') && <Redirect to="/auth" />}
      {content.length != 0 &&
        <SimpleGrid
        mb='0px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>        
         
          <Content
            columnsData={columnsDataComplex}
            tableData={content}
            setContent={setContent}
            refreshContent={refreshContent}
          />
        
      </SimpleGrid>      }
    </Box>
  );
}
