import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

export default function CampaignOverview(props) {  
  const blueTextColor = "rgba(40, 85, 132, 1)"
  const grayTextColor = useColorModeValue("secondaryGray.900", "white");
  const { campaigns, active, passive, updateStatus } = props;
  const [allTextColor, setAllTextColor] = React.useState(blueTextColor);
  const [activeTextColor, setActiveTextColor] = React.useState(grayTextColor);
  const [passiveTextColor, setPassiveTextColor] = React.useState(grayTextColor);

  function updateToAll() {
    setAllTextColor(blueTextColor);
    setActiveTextColor(grayTextColor);
    setPassiveTextColor(grayTextColor);
    updateStatus(2)    
  }

  function updateToActive() {
    setAllTextColor(grayTextColor);
    setActiveTextColor(blueTextColor);
    setPassiveTextColor(grayTextColor);
    updateStatus(1)
  }

  function updateToPassive() {
    setAllTextColor(grayTextColor);
    setActiveTextColor(grayTextColor);
    setPassiveTextColor(blueTextColor);    
    updateStatus(0)
  }

  return (
    <Flex
      px='22px'
      pb='10px'
      pt='10px'
      mb='10px'
      backgroundColor='white'
      borderRadius={10}>

      <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        w='max-content'
        _focus={{ boxShadow: 'none' }}        
        onClick={updateToAll}
      >
        <Text color={allTextColor} fontSize='2xl' fontWeight='700' align={{ base: "start", md: "center" }} >
          {campaigns}
          <Text color={allTextColor} fontSize='sm' fontWeight='400'>
            CAMPAIGNS
          </Text>
        </Text>
      </Button>

      <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        w='max-content'
        _focus={{ boxShadow: 'none' }}
        onClick={updateToActive}
      >
        <Text paddingLeft='50px' color={activeTextColor} fontSize='2xl' fontWeight='700' align={{ base: "start", md: "center" }}>
          {active}
          <Text color={activeTextColor} fontSize='sm' fontWeight='400'>
            ACTIVE
          </Text>
        </Text>
      </Button>

      <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        w='max-content'
        _focus={{ boxShadow: 'none' }}
        onClick={updateToPassive}
      >
        <Text paddingLeft='50px' color={passiveTextColor} fontSize='2xl' fontWeight='700' align={{ base: "start", md: "center" }}>
          {passive}
          <Text color={passiveTextColor} fontSize='sm' fontWeight='400'>
            INACTIVE
          </Text>
        </Text>
      </Button>
    </Flex>
  );
}
