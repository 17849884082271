import {
  Flex,
  Table,
  Image,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import AddContent from "views/super/content/components/AddContent";
// Assets
import { MdModeEdit, MdCancel, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import { fetchUtilViaGet, fetchUtilViaDelete } from '../../../../components/fetch/FetchUtil.js';
import VideoPlayerModal from "views/admin/campaign/components/VideoPlayerModal";
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog.js';
import AWS from 'aws-sdk';
import UpdateContent from "views/super/content/components/UpdateContent";

export default function Users(props) {
  const { columnsData, tableData, setContent, refreshContent } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [newRecord, setNewRecord] = React.useState(null);
  const [modalVideo, toggleModalVideo] = React.useState(false);
  const [modalMedia, setModalMedia] = React.useState('');
  const [videoModalHeader, setVideoModalHeader] = React.useState('');
  const [videoModalUser, setVideoModalUser] = React.useState('');
  const [videoModalDate, setVideoModalDate] = React.useState('');
  const [videoModalHashtags, setVideoModalHashtags] = React.useState([]);

  React.useEffect(() => {        
    if (newRecord === null) {
      return;
    }    
    setContent((prevContent) => [...prevContent, JSON.parse(newRecord)]); 
  }, [newRecord]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Use 'page' instead of the whole 'data' to render rows
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");  
  const [modal, toggleModal] = React.useState(false);
  const [modalUpdate, toggleModalUpdate] = React.useState(false);
  const [updateId, setUpdateId] = React.useState(null);
  const [detail, setDetail] = React.useState({});
  const [edit, setEdit] = React.useState(false);

  function closeModal() {
    toggleModal(false);
  }

  function openModal() {
    toggleModal(true);
  }  

  function closeModalVideo() {
    toggleModalVideo(false);
  }

  function openModalUpdate(id, row, edit) {
    setUpdateId(id);    
    setDetail(row);
    setEdit(edit);
    toggleModalUpdate(true);
  }

  function closeUpdateModal() {
    setUpdateId(null);
    setDetail({});
    if (edit) {
      refreshContent()
    }
    setEdit(false);
    toggleModalUpdate(false);
  }

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteUrl, setDeleteUrl] = React.useState(null);
  const [deleteThumbnailUrl, setDeleteThumbnailUrl] = React.useState(null);
  const [deleteContentUrl, setDeleteContentUrl] = React.useState(null);
  const [deleteContentUrl1, setDeleteContentUrl1] = React.useState(null);
  const [deleteContentUrl2, setDeleteContentUrl2] = React.useState(null);
  const openDialog = (id, url, thumbnailUrl, contentUrl, contentUrl1, contentUrl2) => {
    setDeleteId(id);
    setDeleteUrl(url.replace('https://indashbucket.s3.amazonaws.com/', ''));
    setDeleteThumbnailUrl(thumbnailUrl ? thumbnailUrl.replace('https://indashbucket.s3.amazonaws.com/', '') : null);
    setDeleteContentUrl(contentUrl ? contentUrl.replace('https://indashbucket.s3.amazonaws.com/', '') : null);
    setDeleteContentUrl1(contentUrl1 ? contentUrl1.replace('https://indashbucket.s3.amazonaws.com/', '') : null);
    setDeleteContentUrl2(contentUrl2 ? contentUrl2.replace('https://indashbucket.s3.amazonaws.com/', '') : null);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDeleteId(null);
    setDeleteUrl(null);
    setDeleteThumbnailUrl(null);
    setDeleteContentUrl(null);
    setDialogOpen(false);
  };

  const S3_BUCKET = "indashbucket"; // TODO
  const REGION = "us-east-1"; // TODO
  const deleteContent = async () => {        
    if (deleteId) {
      try {                
        AWS.config.update({
          accessKeyId: "AKIA6A57N643ARJJLBFK", // TODO
          secretAccessKey: "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", // TODO
        });
        const s3 = new AWS.S3({
            params: { 
              Bucket: S3_BUCKET,
              Delete: {
                Objects: deleteContentUrl != null && deleteContentUrl1 == null && deleteContentUrl2 == null ? [
                  { Key: deleteUrl },
                  { Key: deleteThumbnailUrl },
                  { Key: deleteContentUrl }
                ] : 
                deleteContentUrl != null && deleteContentUrl1 != null && deleteContentUrl2 == null ? [
                  { Key: deleteUrl },
                  { Key: deleteThumbnailUrl },
                  { Key: deleteContentUrl },
                  { Key: deleteContentUrl1 }
                ] : 
                deleteContentUrl != null && deleteContentUrl1 != null && deleteContentUrl2 != null ? [
                  { Key: deleteUrl },
                  { Key: deleteThumbnailUrl },
                  { Key: deleteContentUrl },
                  { Key: deleteContentUrl1 },
                  { Key: deleteContentUrl2 }
                ] :
                [
                  { Key: deleteUrl },
                  { Key: deleteThumbnailUrl }
                ],
              },
            },
            region: REGION,            
        });
        s3.deleteObjects(s3.params, (err, data) => {
          if (err) {
            console.error('Error deleting objects:', err);
          } else {
            fetchUtilViaDelete('/deleteContent/'+deleteId);
            setContent((prevContent) => prevContent.filter(item => item.id !== deleteId));
            closeDialog();            
          }
        });        
      } catch (error) {
        console.error(error);
      }
    }    
  };        

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Content List
        </Text>
        <Flex
          w={{ base: '100%', md: '80%', lg: '10%' }}>
          <Button
            fontSize='l'
            variant='brand'
            fontWeight='700'
            borderRadius={10}
            minWidth="90px"
            w='75%'
            h='35px'
            mb='10px'
            mr={3}
            onClick={openModal}>
              <Icon as={MdAdd} w={8} h={8} color={"white"} pr={2} />
              Add
          </Button>          
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='10px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='0px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "8px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>        
        <Tbody {...getTableBodyProps()}>          
          {page && page.map((row, rowIndex) => {
            prepareRow(row);
            const rowData = { ...row.original };
            return (
              <Tr key={rowIndex}>
                {columns.map((column, colIndex) => {
                  let data = '';
                  if (column.Header === '#') {
                    data = (
                      <Flex align='center' maxWidth={1}>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {rowIndex + pageIndex * pageSize + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'CHANNEL') {
                    data = (
                      <Flex align='center'>                        
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {rowData.channel}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'TYPE') {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {rowData.type}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'INFLUENCER') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {rowData.influencer}
                      </Text>
                    );
                  } else if (column.Header === 'CAMPAIGN') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {rowData.campaign}
                      </Text>
                    );
                  } else if (column.Header === 'DETAILS') {
                    data = (                      
                      <Button                      
                        h='35px'
                        onClick={() => openModalUpdate(rowData.insight_id, rowData, false)}
                        >
                        <Icon as={CgDetailsMore} w={5} h={5} />
                      </Button>
                      );
                  } else if (column.Header === 'Content') {
                    data = (
                      <Image
                        src={row.type == 'POST' || row.type == 'Post' ? rowData.url : rowData.thumbnail_url}
                        w={{ base: "50%", "sm": "50%" }}
                        h={{ base: "50%", "sm": "50%" }}
                        maxH={{ base: "8%", "sm": "8%" }}
                        minW={"80px"}
                        borderRadius='10px'
                        onClick={() => {
                          toggleModalVideo(true);
                          setModalMedia(rowData.url);
                          setVideoModalHeader(rowData.channel + " " + rowData.type);
                          setVideoModalUser(rowData.influencer);
                          setVideoModalDate(rowData.added_date);
                          //setVideoModalHashtags(row.hashtags);
                        }} 
                      />     
                    );
                  } else if (column.Header === 'EDIT') {
                    data = (                      
                    <Button                      
                      h='35px'
                      onClick={() => openModalUpdate(rowData.insight_id, rowData, true)}
                      >
                      <Icon as={MdModeEdit} w={5} h={5} />
                    </Button>
                    );
                  } else if (column.Header === 'DELETE') {
                    data = (                      
                    <Button                      
                      h='35px'
                      onClick={() => openDialog(rowData.id, rowData.url, rowData.thumbnail_url, rowData.content_url)}
                      >
                      <Icon as={MdCancel} w={5} h={5} color={"red"} />                      
                    </Button>
                    );
                  } 
                  return (
                    <Td                      
                      key={colIndex}
                      fontSize={{ sm: '14px' }}
                      maxH='30px !important'
                      maxW={"150px"}
                      py='8px'
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor='transparent'
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>        
      </Table>
      <Flex justifyContent="flex-end" m={4} alignItems="center">
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>
          <Text pr={4}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Text>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >            
            {[10, 20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Flex>
      <AddContent modal={modal} closeModal={closeModal} setNewRecord={setNewRecord}/>      
      <UpdateContent modal={modalUpdate} closeModal={closeUpdateModal} updateId={updateId} detail={detail} edit={edit} />
      <VideoPlayerModal modal={modalVideo} closeModal={closeModalVideo} modalMedia={modalMedia} videoModalHeader={videoModalHeader} videoModalUser={videoModalUser} videoModalDate={videoModalDate} />
      <ConfirmationDialog
          isOpen={isDialogOpen}
          onRequestClose={closeDialog}
          onConfirm={deleteContent}
          message="Are you sure you want to perform this action?"
        />
    </Card>    
  );
}
