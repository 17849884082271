/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Redirect } from 'react-router-dom';
import CircleLoader from "react-spinners/CircleLoader";
import ProgressOverTime from "./components/ProgressOverTime2";

const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function UserReports(props) { 
  let [loading, setLoading] = React.useState(false);  

  return (    
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>                   
      {!localStorage.getItem('authenticated') && <Redirect to="/auth" />}      
      <ProgressOverTime />      
    </Box>
  );
}
