// Chakra imports
import {Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Scatter } from '@ant-design/charts';
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';

export default function ComparisonByAvgMetricsChart(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  
  let [data, setData] = React.useState([]);  

  React.useEffect(() => {
	  const comparisonByAvgMetrics = async () => {			
      try {        
        const response = await fetchUtilViaGet('/comparisonByAvgMetrics?brand_id='+localStorage.getItem('brand_id'));        
        const updatedData = response.map((item) => {
          return {
            "IG ER": item.iger,
            "IG Follower": item.igfollower,
            User: item.instagram_username
          };
        });
        setData(updatedData);
      } catch (error) {
        console.error("comparisonByAvgMetrics:" + error);
      }
	  };			
	  comparisonByAvgMetrics();
	}, []);
  
  function formatNumber(number) {
    if (number >= 1e9) {
      // Billion or more
      return (number / 1e9).toFixed(1) + "B";
    } else if (number >= 1e6) {
      // Million or more
      return (number / 1e6).toFixed(1) + "M";
    } else if (number >= 1e3) {
      // Thousand or more
      return (number / 1e3).toFixed(1) + "K";
    } else if (number == null) {
      return "0";
    } else {
      return number;
    }
  }
  
  const comparisonByAvgMetricOptions = {    
    appendPadding: 10,
    data,
    xField: 'IG Follower',
    yField: 'IG ER',
    shape: 'circle',
    colorField: 'User',
    size: 4,
    yAxis: {      
      nice: true,
      line: {
        style: {
          stroke: '#aaa',
        },
      },      
    },
    xAxis: {
      min: 0,
      grid: {
        line: {
          style: {
            stroke: '#eee',
          },
        },
      },
      line: {
        style: {
          stroke: '#aaa',
        },
      },
      label: {
        formatter: (v) => formatNumber(v),
      },      
    },
    legend: false,    
  };

  return (
    <Flex 
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}>
        <Text
          me='auto'
          color={textColor}
          fontSize='l'
          fontWeight='700'
          lineHeight='100%'
          width={"100%"}    
          >
          INF. COMPARISON BY AVG. METRICS
        </Text>
        {comparisonByAvgMetricOptions && <Scatter {...comparisonByAvgMetricOptions} />}
    </Flex>    
  );
}
