import React, { forwardRef, useImperativeHandle } from "react";
import {
    Flex,
    Icon,
    Text,
    Box,
    useColorModeValue,
    Image,
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";

const Upload = forwardRef((props, ref) => {
    const { thumbnail, setFile, setFile1, setFile2, setThumbnail, disabled, selectedType, contentFile } = props;
    const brandColor = useColorModeValue("brand.500", "white");
    const bg = useColorModeValue("gray.100", "navy.700");
    const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");    
    const videoRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const [thumbnailUrl, setThumbnailUrl] = React.useState("");
    const [thumbnailUrl1, setThumbnailUrl1] = React.useState("");
    const [thumbnailUrl2, setThumbnailUrl2] = React.useState("");

    const handleFileChange = (e) => {
        if (e.target.files === null || e.target.files === undefined) {
            return;
        }
        if(e.target.files.length>3) {
            alert("You can upload maximum 3 files.");
            document.getElementById('files').value = "";
            return;
        } 
        const uploadedFile = e.target.files[0];
        const uploadedFile1 = e.target.files[1];
        const uploadedFile2 = e.target.files[2];
        setFile(uploadedFile);
        if (uploadedFile1) setFile1(uploadedFile1);
        if (uploadedFile2) setFile2(uploadedFile2);
        if (uploadedFile && !contentFile) {
            const videoURL = URL.createObjectURL(uploadedFile);            
            videoRef.current.src = videoURL;
            captureThumbnail();
        }
        if ((contentFile || selectedType == 2) && uploadedFile) {
            const fileUrl = URL.createObjectURL(uploadedFile);
            setThumbnailUrl(fileUrl);
        }
        if ((contentFile || selectedType == 2) && uploadedFile1) {
            const fileUrl1 = URL.createObjectURL(uploadedFile1);
            setThumbnailUrl1(fileUrl1);
        }
        if ((contentFile || selectedType == 2) && uploadedFile2) {
            const fileUrl2 = URL.createObjectURL(uploadedFile2);
            setThumbnailUrl2(fileUrl2);
        }
    };

    const captureThumbnail = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
    
        video.addEventListener('canplaythrough', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);    
            const thumbnailDataURL = canvas.toDataURL('image/jpeg');            
            setThumbnailUrl(thumbnailDataURL);
            setThumbnail(thumbnailDataURL); 
        });        
        video.currentTime = 3;
    };

    return (
        <>
            <form className='upload-steps' onSubmit={handleFileChange} >
                <button type='submit' >
                    <Box
                        align='center'
                        padding={"8px"}
                        justify='center'                        
                        bg={bg}
                        border='1px dashed'
                        borderColor={borderColor}
                        borderRadius='14px'
                        w='100%'                        
                        h='max-content'
                        minH='100%'
                        minHeight={"70px"}
                        cursor='pointer'
                        size='lg'
                        mb='12px'
                    >
                        <Flex direction={"row"} alignItems={"center"}>
                            <Icon as={MdUpload} w='30px' h='30px' pr="10px" pt={"10px"} color={brandColor} />                            
                            <input id="files" type="file" accept={selectedType == 2 ? "image/*" : "video/*"} style={{paddingTop:'10px'}} onChange={handleFileChange} disabled={disabled} multiple={selectedType == 2 ? true : false}/>
                            {thumbnailUrl && 
                            <Image
                                src={thumbnailUrl}                                                                
                                borderRadius='6px'    
                                maxHeight={"55px"}
                                /* onClick={() => {
                                toggleModal(true);
                                setModalMedia("https://scontent.cdninstagram.com/v/t66.30100-16/120076294_254983823993877_8999275851357549228_n.mp4?_nc_ht=scontent.cdninstagram.com&_nc_cat=109&_nc_ohc=nMzKsXv9X78AX_LXLlW&edm=APs17CUBAAAA&ccb=7-5&oh=00_AfAz11IOSC_6dfaFgNrgoVlfX_FGOEeKmWBrk2LrA2qJZQ&oe=650E49FF&_nc_sid=10d13b");
                                }} */
                            />}
                            {thumbnailUrl1 && 
                            <Image
                                src={thumbnailUrl1}                                                                
                                borderRadius='6px'    
                                maxHeight={"35px"}
                                /* onClick={() => {
                                toggleModal(true);
                                setModalMedia("https://scontent.cdninstagram.com/v/t66.30100-16/120076294_254983823993877_8999275851357549228_n.mp4?_nc_ht=scontent.cdninstagram.com&_nc_cat=109&_nc_ohc=nMzKsXv9X78AX_LXLlW&edm=APs17CUBAAAA&ccb=7-5&oh=00_AfAz11IOSC_6dfaFgNrgoVlfX_FGOEeKmWBrk2LrA2qJZQ&oe=650E49FF&_nc_sid=10d13b");
                                }} */
                            />}
                            {thumbnailUrl2 && 
                            <Image
                                src={thumbnailUrl2}                                                                
                                borderRadius='6px'    
                                maxHeight={"35px"}
                                /* onClick={() => {
                                toggleModal(true);
                                setModalMedia("https://scontent.cdninstagram.com/v/t66.30100-16/120076294_254983823993877_8999275851357549228_n.mp4?_nc_ht=scontent.cdninstagram.com&_nc_cat=109&_nc_ohc=nMzKsXv9X78AX_LXLlW&edm=APs17CUBAAAA&ccb=7-5&oh=00_AfAz11IOSC_6dfaFgNrgoVlfX_FGOEeKmWBrk2LrA2qJZQ&oe=650E49FF&_nc_sid=10d13b");
                                }} */
                            />}
                        </Flex>                        
                        {/* <Text fontSize="11px" fontWeight='500' color='secondaryGray.500'>
                                PNG, JPG and MP4 files are allowed.
                        </Text> */}
                    </Box>
                    <canvas ref={canvasRef} hidden={true} style={{ display: 'none' }}></canvas>                                    
                    <video ref={videoRef} hidden={true} controls></video>
                </button>
            </form>
        </>
    );
});

export default Upload;