export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "#",
    accessor: "#",
  },
  {
    Header: "CHANNEL",
    accessor: "channel",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "INFLUENCER",
    accessor: "influencer",
  },
  {
    Header: "CAMPAIGN",
    accessor: "campaign",
  },  
  {
    Header: "DETAILS",
    accessor: "details",
  },
  {
    Header: "EDIT",
    accessor: "edit",
  },
  {
    Header: "Content",
    accessor: "content",
  },
  {
    Header: "DELETE",
    accessor: "delete",
  },
];

export const columnsContentDistributionChartColumns = [
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "COUNT",
    accessor: "count",
  }
];