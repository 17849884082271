import React from "react";

import { Icon } from "@chakra-ui/react";
import {AiOutlineUserAdd} from "react-icons/ai";
import {SiBrandfolder} from "react-icons/si";
import {MdCampaign,MdContentCopy} from "react-icons/md";
import {BsFillPersonLinesFill} from "react-icons/bs";
import {FaHashtag} from "react-icons/fa";
import User from "views/super/user";
import Brand from "views/super/brand";
import Campaign from "views/super/campaign";
import Influencer  from "views/super/influencer";
import Hashtag from "views/super/hashtag";
import Content from "views/super/content";

const superRoutes = [    
  {
    name: "Brand",
    layout: "/super",
    path: "/brand",
    icon: <Icon as={SiBrandfolder} width='20px' height='20px' color='inherit' />,
    component: Brand,
  },
  {
    name: "User",
    layout: "/super",
    path: "/user",
    icon: <Icon as={AiOutlineUserAdd} width='20px' height='20px' color='inherit' />,
    component: User,
  },
  {
    name: "Campaign",
    layout: "/super",
    path: "/campaign",
    icon: <Icon as={MdCampaign} width='20px' height='20px' color='inherit' />,
    component: Campaign,
  },
  {
    name: "Influencer",
    layout: "/super",
    path: "/influencer",
    icon: <Icon as={BsFillPersonLinesFill} width='20px' height='20px' color='inherit' />,
    component: Influencer,
  },
  /* {
    name: "Hashtag",
    layout: "/super",
    path: "/hashtag",
    icon: <Icon as={FaHashtag} width='20px' height='20px' color='inherit' />,
    component: Hashtag,
  }, */
  {
    name: "Content",
    layout: "/super",
    path: "/content",
    icon: <Icon as={MdContentCopy} width='20px' height='20px' color='inherit' />,
    component: Content,
  },
];

export default superRoutes;
