// Chakra imports
import { Box, Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import ComparisonByAvgMetricsChart from "./ComparisonByAvgMetricsChart";
import ContentDistributionChart from "./ContentDistributionChart";
import PostConversationOverTimeChart from "./PostConversationOverTimeChart";
import StoriesConversationOverTimeChart from "./StoriesConversationOverTimeChart";
import YoutubeConversationOverTimeChart from "./YoutubeConversationOverTimeChart";
import TikTokConversationOverTimeChart from "./TikTokConversationOverTimeChart";
import InstagramStoryLinksChart from "./InstagramStoryLinksChart";
import PeriodicGAinsOfContentAndInsights from "./PeriodicGainsOfContentAndInsights";

export default function ProgressOverTime(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex flexDirection={"column"}>
        {/* <Text color={textColor} fontSize='2xl' ms='24px' mb='10px' fontWeight='700'>
            Progress Over Time
        </Text>   */}
        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='10px' mb='10px'>
            {/* <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <ContentDistributionChart />
            </Card> */}
            <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <ComparisonByAvgMetricsChart />
            </Card>
            <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <PostConversationOverTimeChart />
            </Card>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='10px' mb='10px'>
            <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <StoriesConversationOverTimeChart />
            </Card>
            {/* <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <YoutubeConversationOverTimeChart />
            </Card>
            <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <TikTokConversationOverTimeChart />
            </Card> */}
            {/* <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <InstagramStoryLinksChart />              
            </Card> */}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='10px' mb='10px'>
            {/* <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <InstagramStoryLinksChart />              
            </Card> */}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='10px'>
            {/* <Card align='center' direction='column' w='100%' h='300px' {...rest}>
              <PeriodicGAinsOfContentAndInsights />              
            </Card> */}
        </SimpleGrid>
    </Flex>    
  );
}
