import React from "react";

// Chakra imports
import {
  Box,
  Menu,
	MenuButton,
	Checkbox,
	MenuList,	
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue, 
  Icon, 
} from "@chakra-ui/react";

import Media2 from "components/card/Media2.js";
import Nft1 from "assets/img/nfts/Nft1.png";
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import Tooltip from "components/tooltip/Tooltip.js";
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';

export default function Campaign(props) {
  let menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const {brand, campaignName, campaignStartDate, campaignEndDate, campignStatus, campaignId, toggleModal, 
    setModalMedia, setVideoModalHeader, setVideoModalUser, setVideoModalDate, setVideoModalHashtags } = props;
  const [totalContent, setTotalContent] = React.useState('');
  const [totalInfluencer, setTotalInfluencer] = React.useState('');
  const [totalSocialReach,setTotalSocialReach] = React.useState('');
  const [totalImpressionCount,setTotalImpressionCount] = React.useState('');
  const [totalReach,setTotalReach] = React.useState('');
  const [totalEngagement,setTotalEngagement] = React.useState('');
  const [totalVideoView,setTotalVideoView] = React.useState('');
  const [totalLinkClick,setTotalLinkClick] = React.useState('');
  const [content,setContent] = React.useState([]);    
  const [influencerCounts, setInfluencerCounts] = React.useState([]);

  React.useEffect(() => {
	  const getCampaignTotalDetail = async () => {			
      try {
        const response = await fetchUtilViaGet('/campaignTotalDetail?campaign_id='+campaignId);             
        if (response.length === 0) {
          setTotalSocialReach(0);
          setTotalImpressionCount(0);
          setTotalReach(0);
          setTotalEngagement(0);
          setTotalVideoView(0);
          setTotalLinkClick(0);
          setContent([]);
        } else {
          setTotalSocialReach(response[0].total_like_count + response[0].total_comment_count  + response[0].total_save_count + response[0].total_share_count);
          setTotalImpressionCount(response[0].total_impression_count);
          setTotalReach(response[0].total_reach);
          setTotalEngagement(response[0].total_engagement);
          setTotalVideoView(response[0].total_video_view);
          setTotalLinkClick(response[0].total_link_click);
          const content = await fetchUtilViaGet('/contentByCampaignId?campaign_id='+campaignId);          
          setContent(content);                    
        }
        const influencerCounts = await fetchUtilViaGet('/influencerContentByCampaignId?campaign_id='+campaignId);                    
        setInfluencerCounts(influencerCounts.sort((a, b) => b.media_count - a.media_count));
      } catch (error) {
        console.error(error);
      }
	  };			
	  getCampaignTotalDetail();
	}, [campaignId]);

  React.useEffect(() => {
	  const getCampaignInfluencerByCampaignId = async () => {			
      try {
        const response = await fetchUtilViaGet('/campaignInfluencerByCampaignId?campaign_id='+campaignId);     
        if (response.length === 0) {
          setTotalInfluencer(0);
        } else {
          setTotalInfluencer(response[0].numberOfInfluencer);
        }
      } catch (error) {
        console.error(error);
      }
	  };			
	  getCampaignInfluencerByCampaignId();
	}, [campaignId]);

  React.useEffect(() => {
	  const getMediaCountByCampaignIdAndChannel = async () => {			
      try {
        const response = await fetchUtilViaGet('/mediaCountByCampaignIdAndChannel?campaign_id='+campaignId+'&channel=1');     
        if (response.length === 0) {
          setTotalContent(0);
        } else {
          setTotalContent(response[0].contentCount);
        }
      } catch (error) {
        console.error(error);
      }
	  };			
	  getMediaCountByCampaignIdAndChannel();
	}, [campaignId]);
  
  const formatDate = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-based
    const day = String(originalDate.getDate()).padStart(2, "0");    
    const formattedDateString = `${day}/${month}/${year}`;
    return formattedDateString;
  };

  function formatNumber(number) {
    if (number >= 1e9) {
      // Billion or more
      return " " + (number / 1e9).toFixed(1) + " B";
    } else if (number >= 1e6) {
      // Million or more
      return " " + (number / 1e6).toFixed(1) + " M";
    } else if (number >= 1e3) {
      // Thousand or more
      return " " + (number / 1e3).toFixed(1) + " K";
    } else if (number == null) {
      return "0";
    } else {      
      return " " + number;
    }
  }

  return (
      <Flex
        px='22px'
        pb='10px'
        pt='10px'
        mb='10px'
        backgroundColor='white'
        borderRadius={10}
        flexDirection="column" >
        <Flex fontSize={{ base: "sm", md: "sm", sm: "xx-small" }}>
          <Text color='white' backgroundColor='black' borderRadius={8} px={"3"} fontWeight='700' py={"2px"}>
            {brand}
          </Text>             
          <Text paddingLeft='10px' color={textColor} fontWeight='500' >
            {campaignName}                   
          </Text>
          <Flex 
            marginLeft='auto'
            marginRight='10px'>
            <Text color='rgba(40,85,132, 1)' paddingRight='5' fontWeight='700' >
              {formatDate(campaignStartDate)} - {formatDate(campaignEndDate)}
            </Text>
            <Text color='White' backgroundColor='rgba(40,85,132, 1)' borderRadius={8} px={"3"} fontWeight='500' py={"2px"}>
              {campignStatus}
            </Text>      
          </Flex>                  
        </Flex>      
        <Flex 
          overflowX="scroll"          
          width="100%"
          pt='10px'
          pb='5px'>
          {content && content.map((row, index) => (
            <Box key={index} marginRight="0px">
              <Media2 image={row.thumbnail_url != null ? row.thumbnail_url : row.url} 
                      toggleModal={toggleModal} 
                      row={row} 
                      setModalMedia={setModalMedia}
                      setVideoModalHeader={setVideoModalHeader}
                      setVideoModalUser={setVideoModalUser}
                      setVideoModalDate={setVideoModalDate}
                      setVideoModalHashtags={setVideoModalHashtags}                      
                      />
            </Box>
          ))}
        </Flex>
        <Flex
          pb='5px'
          fontSize={{ base: "sm", md: "2xl", lg: "2xl" }}          
          >
          <SimpleGrid columns={{ sm: 1, md: 2 }} width={"100%"}>
            <Flex pb={"5px"} >
              <Menu>
                <MenuButton p="0px" flexDirection="row">
                  <Flex
                    flexDirection="row"
                    minWidth={"90px"}
                    >
                    <Text color={textColor} fontWeight='700' textAlign="center" >
                      {totalInfluencer}         
                      <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' style={{ textDecoration: 'underline' }}>
                        Influencers       
                      </Text>
                    </Text>    
                  </Flex>
                </MenuButton>
                <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="7px" bg={menuBg} border="none" fontSize={{base: 'sm', md: "sm", lg: "sm"}}>
                  <Flex mb="0px" flexDirection="column" pl={2} pt={1} pb={1}>                    
                    <Text color={textColor} ms='4px' mb='10px' fontWeight='700'>
                      Influencers
                    </Text>
                    {influencerCounts && Object.keys(influencerCounts).map((key, index) => (                      
                      <Flex flexDirection="row" my={"1px"} backgroundColor={boxBg} borderRadius={8} py={"2px"} px={"2px"} mx={"2px"}>
                        <Text>{influencerCounts[key].influencer_name}</Text>
                        <Text marginLeft={'auto'} mr={"10px"} color='white' backgroundColor='rgba(223,36,76,0.8)' borderRadius={8} px={"5px"} fontWeight='500' maxW={"45px"} minW={"45px"} py={"1px"} display="flex" alignItems="center">
                          <Icon w='17px' h='17px' as={AiOutlineInstagram} paddingRight='1px' color={"black"} />
                          {influencerCounts[key].media_count}         
                        </Text>                        
                      </Flex>
                    ))}
                  </Flex>
                </MenuList>
              </Menu>                            
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
                <Tooltip text={totalSocialReach}>
                  {formatNumber(totalSocialReach)}  
                </Tooltip>                
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Social Reach
                </Text>
              </Text>            
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
                {totalContent} 
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Content
                </Text>
              </Text>
            </Flex>
            <Flex              
              minW={500}
              justifyContent={{ base: "left", md: "right" }}
              ml={{base: '-50px', sm: "0px", md: '-80px'}}
              >
              <Text color={textColor} fontWeight='700' textAlign="center">                
                <Tooltip text={totalImpressionCount}>
                  {formatNumber(totalImpressionCount)}
                </Tooltip>     
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Impressions
                </Text>
              </Text>     
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
                <Tooltip text={totalReach}>
                  {formatNumber(totalReach)}
                </Tooltip>                     
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Reach
                </Text>
              </Text>  
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">                
                <Tooltip text={totalVideoView}>
                  {formatNumber(totalVideoView)}
                </Tooltip>                     
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Video Views
                </Text>
              </Text>     
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
                <Tooltip text={totalEngagement}>
                  {formatNumber(totalEngagement)}
                </Tooltip>                                    
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Engagements
                </Text>
              </Text>  
              <Text pl='25px' color={textColor} fontWeight='700' textAlign="center">
                <Tooltip text={totalLinkClick}>
                  {formatNumber(totalLinkClick)}
                </Tooltip>                         
                <Text color={textColor} fontSize={{base: 'xx-small', md: "sm", lg: "sm"}} fontWeight='500' >
                  Link Visit
                </Text>
              </Text>  
            </Flex>       
          </SimpleGrid>                     
        </Flex>    
      </Flex>
  );
}
