import React from 'react';
import Modal from 'react-modal';
import {
  Flex,
} from "@chakra-ui/react";

// Define the styles for the modal
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    width: '300px',
    margin: '0 auto',
    top: '25%',
    left: '0',
    right: '0',
    bottom: 'auto',
  },
};

Modal.setAppElement('#root'); // Set the root element for the modal

function ConfirmationDialog({ isOpen, onRequestClose, onConfirm, message }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Confirmation Dialog"
    >
      <div>{message}</div>
      <Flex
        direction="row"
        mt={2}
        ml="80px"
      >
        <button onClick={onRequestClose}>
          <Flex
            mr="10px"
            ml={8}
            backgroundColor={"red"}
            borderRadius="8px"
            p={1}
          >Cancel
          </Flex>
        </button>

        <button onClick={onConfirm}>
          <Flex
            mr="auto"
            ml={0}
            backgroundColor={"green"}
            borderRadius="8px"
            p={1}
            pl={4}
            pr={4}
          >OK</Flex></button>

      </Flex>
    </Modal>
  );
}

export default ConfirmationDialog;
