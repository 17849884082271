// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Box,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { HiOutlineBackspace } from 'react-icons/hi';
import routes from 'routes.js';
import superRoutes from 'superRoutes.js';
import { ThemeEditor } from './ThemeEditor';
import { fetchUtilViaGet } from '../fetch/FetchUtil.js';
import { Network } from './Network';
import { TimeFilter } from './TimeFilter';
import { CampaignFilter } from './CampaignFilter';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { IoMdMoon, IoMdSunny } from "react-icons/io";

export default function HeaderLinks(props) {
	const { secondary } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	const [name, setName] = useState('');
	const [logout, setLogout] = useState(false);
	const [goBackButtonVisible, setGoBackButtonVisible] = useState(false);
	const [superAddress, setSuperAddress] = useState(false);
	const [filtersVisible, setFiltersVisible] = useState(false);
	const location = useLocation();
	const { colorMode, toggleColorMode } = useColorMode();
	const [nameSurname, setNameSurname] = useState('');
	const sleep = ms => new Promise(r => setTimeout(r, ms));
	
	useEffect(() => {		
		getUserInformation();
	}, []);

	const getUserInformation = async () => {
		await sleep(300)
		try {
			const response = await fetchUtilViaGet('/user?email='+localStorage.getItem('email'));
			setName(response[0].name);
			setNameSurname(response[0].name + ' ' + response[0].surname)
		} catch (error) {
			console.error(error);
		}
	};

	const handleLogput = () => {
		localStorage.removeItem('email');
		localStorage.removeItem('password');
		localStorage.removeItem('authenticated');
		localStorage.removeItem('keepMeLogin');
		localStorage.removeItem('authenticationType');
		localStorage.removeItem('brand_id');
		localStorage.removeItem('user_id');
		setLogout(true);
	};

	const history = useHistory();

	const handleGoBack = () => {
		history.push('/super/brand');
	};
	const isCampaignPage = location.pathname === '/admin/campaign';

	useEffect(() => {
		const currentPath = location.pathname;
		const isSuperLink = currentPath.includes('super');
		setGoBackButtonVisible(isSuperLink);
		setSuperAddress(isSuperLink);
		const isAdminLink = currentPath.includes('admin');
		setFiltersVisible(isAdminLink);
	}, []);

	return (
		<Flex
			w={{ base: '100%', sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'wrap'}
			p="10px"
			borderRadius="10px"
			boxShadow={shadow}
			gap="10px">
			{logout && <Redirect to="/auth" />}
			{filtersVisible &&
				<>
					<Box borderRadius={8} borderStyle='dashed' borderWidth={1} p='5px' pl='5px' minWidth={"150px"}>
						<Flex flexDirection="row">
							<Text
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="700"
								color={textColor}
								pr={1}>
								Brand :
							</Text>
							<Text
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="500"
								color={textColor}>
								Philips - Domestic
							</Text>
							{/** TODO */}
						</Flex>
					</Box>
					<Box > <Network /> </Box>
					{/* <Box > <TimeFilter /> </Box> TODO */}
					{/* {isCampaignPage && (<Box > <CampaignFilter /> </Box>)}	TODO */}
					{/* <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="10px" /> */}
				</>}
			{localStorage.getItem('authenticationType') == 1 &&
				<Button hidden={goBackButtonVisible}>
					<Icon as={HiOutlineBackspace} w={8} h={8} pr={1} onClick={handleGoBack} />
				</Button>
			}
			<SidebarResponsive routes={superAddress ? superRoutes : routes} />
			{/* <Menu>
				<MenuButton p="0px">
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							Notifications
						</Text>
						<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Influencer Analytics" aName="Alicia" />
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon Design System Free" aName="Josh Henry" />
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu> */}

			{/* <Menu>
					<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={MdInfoOutline}
						color={navbarIcon}
						w='18px'
						h='18px'
						me='10px'
					/>
					</MenuButton>
					<MenuList
					boxShadow={shadow}
					p='20px'
					me={{ base: "30px", md: "unset" }}
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					minW={{ base: "unset" }}
					maxW={{ base: "360px", md: "unset" }}>
					<Image src={navImage} borderRadius='16px' mb='28px' />
					<Flex flexDirection='column'>
						<Link
						w='100%'
						href='https://horizon-ui.com/pro?ref=horizon-chakra-free'>
						<Button w='100%' h='44px' mb='10px' variant='brand'>
							Buy Horizon UI PRO
						</Button>
						</Link>
						<Link
						w='100%'
						href='https://horizon-ui.com/documentation/docs/introduction?ref=horizon-chakra-free'>
						<Button
							w='100%'
							h='44px'
							mb='10px'
							border='1px solid'
							bg='transparent'
							borderColor={borderButton}>
							See Documentation
						</Button>
						</Link>
						<Link
						w='100%'
						href='https://github.com/horizon-ui/influencer-analytics'>
						<Button
							w='100%'
							h='44px'
							variant='no-hover'
							color={textColor}
							bg='transparent'>
							Try Horizon Free
						</Button>
						</Link>
					</Flex>
					</MenuList>
				</Menu> */}
			{/* <Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='18px'
				w='max-content'
				onClick={toggleColorMode}>
				<Icon
					me='10px'
					h='18px'
					w='18px'
					color={navbarIcon}
					as={colorMode === "light" ? IoMdMoon : IoMdSunny}
				/>
			</Button> */}
			{/* <ThemeEditor navbarIcon={navbarIcon} />  */}

			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={nameSurname}
						bg="#11047A"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, {name}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						{/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem> */}
						{/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem> */}
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px"
							onClick={handleLogput}>
							<Text fontSize="sm">Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
