// Define fetchUtil as a function
const fetchUtil = async (url, body, contentType) => {
  return fetch('http://ec2-16-170-140-65.eu-north-1.compute.amazonaws.com:8080/api/v1' + url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/' + (contentType === 'json' ? 'json' : 'x-www-form-urlencoded'),
        'credentials': 'omit',
      },
      body: body,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.error(error);
    });
};

// Define fetchUtilViaGet as a function
const fetchUtilViaGet = async (url) => {
  return fetch('http://ec2-16-170-140-65.eu-north-1.compute.amazonaws.com:8080/api/v1' + url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Add the return statement here
    })
    .then((responseJson) => {
      return responseJson.response;
    })
    .catch((error) => {
      console.error(error);
    });
};

const fetchUtilViaDelete = async (url) => {
  return fetch('http://ec2-16-170-140-65.eu-north-1.compute.amazonaws.com:8080/api/v1' + url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Add the return statement here
    })
    .then((responseJson) => {
      return responseJson.response;
    })
    .catch((error) => {
      console.error(error);
    });
};

// Export the functions so they can be used in other modules
export { fetchUtil, fetchUtilViaGet, fetchUtilViaDelete };
