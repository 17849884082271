import {
    Flex,
    Icon,
    Text,
    Button,
    Select,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import Modal from 'react-modal';
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import { fetchUtil } from '../../../../components/fetch/FetchUtil.js';
import CircleLoader from "react-spinners/CircleLoader";

const override = {
    display: "block",
    marginLeft: "3%",
    marginTop: "1%",
    borderColor: "red",
};

const customStyles = {
    content: {
        margin: 'auto',
        width: '70%',
        maxHeight: '75%',
        borderRadius: '10px',
        padding: '50px',
        overflowY: 'auto',
    },
};

export default function AddCampaign(props) {
    const { modal, closeModal } = props;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [date, setDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date(Date.now() + 90 * 24 * 60 * 60 * 1000));
    const [loading, setLoading] = React.useState(false);
    const [selectedBrand, setSelectedBrand] = React.useState(-1);
    const [selectedBrandName, setSelectedBrandName] = React.useState('');
    const [brands, setBrands] = React.useState([]);
    const [campaignName, setCampaignName] = React.useState('');
    const [addButtonDisabled, setAddButtonDisabled] = React.useState(false);

    React.useEffect(() => {
        const getBrands = async () => {
            try {
                const response = await fetchUtilViaGet('/brand?user_id=' + localStorage.getItem('user_id'));
                setBrands(response);
            } catch (error) {
                console.error(error);
            }
        };
        getBrands();
    }, []);

    const addCampaign = async () => {
        setLoading(true);
        const request = JSON.stringify({
            brand_id: selectedBrand,
            name: campaignName,
            start_date: formattedDate(date),
            end_date: formattedDate(endDate),
            statu: '1'
        });
        
        await fetchUtil('/addCampaign', request, 'json')
            .then((response) => {
                setLoading(false);
                setAddButtonDisabled(false);
                closeModal();
            }).catch((error) => {
                console.log(error);
                setAddButtonDisabled(false);
                setLoading(false);
            })
    };

    function formattedDate(date) {        
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      
    React.useEffect(() => {
        if (selectedBrand !== -1 && campaignName !== '') {
            setAddButtonDisabled(false);
        } else {
            setAddButtonDisabled(true);
        }        
    }, [selectedBrand, campaignName]);

    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}
            preventScroll={false}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Flex
                        direction='row'>
                        <Heading color={textColor} fontSize='36px' mb='10px'>
                            Add Campaign
                        </Heading>
                        <Button
                            h='35px'
                            ml={600}
                            mt={-30}
                            onClick={closeModal}>
                            <Icon as={MdCancel} w={5} h={5} />
                        </Button>
                    </Flex>
                </Box>
                <Flex
                    direction='row'
                    mb={"24px"}>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}          >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Brand<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Select
                                placeholder="Select Brand"
                                isRequired={true}
                                onChange={(e) => {
                                    setSelectedBrand(e.target.value)
                                    setSelectedBrandName(e.target.options[e.target.selectedIndex].text);
                                }}
                                fontSize='sm'
                                mb='24px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                {
                                    brands && brands.map((item, index) => (
                                        <option value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Select>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Start Date<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Box borderRadius={14} mb='8px' borderWidth={1} p='9px' pl='15px' w="100%">
                                <DatePicker
                                    selected={date}
                                    onChange={date => setDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    disabled={loading}

                                />
                            </Box>
                        </FormControl>
                    </Flex>
                    <Flex mr={10}></Flex>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Campaign Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder='Campaign Name'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setCampaignName(e.target.value)}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                End Date<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Box borderRadius={14} mb='8px' borderWidth={1} p='9px' pl='15px' w="100%">
                                <DatePicker
                                    selected={endDate}
                                    onChange={endDate => setEndDate(endDate)}
                                    dateFormat="dd/MM/yyyy"
                                    disabled={loading}

                                />
                            </Box>
                        </FormControl>
                    </Flex>
                </Flex>
                <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='47%'
                    h='50'
                    mb='24px'
                    left='auto'
                    right='-52%'
                    onClick={addCampaign}
                    disabled={addButtonDisabled}
                    _hover={{ bg: "brand.500" }}
                    >
                    Add
                    {loading ?
                    <CircleLoader
                        color={"darkgray"}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    /> : <></>}     
                </Button>
            </Flex>
        </Modal>
    );
}
