import React from "react";
import {
  Flex,
  Text,
  Icon,
  Menu,
	MenuButton,
	MenuItem,
	MenuList,	
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { BiLogoTiktok } from 'react-icons/bi';

export function Network(props) {  
  const { variant, background, children, placeholder, borderRadius, ...rest } = props;  
  let menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');  
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = () => {
    console.log("TimeFilter clicked")
  };
  
  const buttonStyles = {
    backgroundColor: isHovered ? "rgba(240, 240, 240, 0.7)" : "white",
    color: "white",
    transition: "background-color 0.3s", // Add a smooth transition
    borderRadius: "10px",
  };

  return (    
    
    <Flex
      paddingRight={5}
      paddingLeft={5}
      flexDirection="row"
      borderRadius={8} borderStyle='dashed' borderWidth={1} p='5px' pl='5px'       
      borderColor={borderColor}
      onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
        <Menu>
          <MenuButton p="0px" flexDirection="row">
            <Flex
              flexDirection="row"
              minWidth={"90px"}
              >
              <Text color={textColor} fontSize='sm' fontWeight='700' pl='3px'>
                Network |
              </Text>   
              <Icon mt="4px" as={AiOutlineInstagram} w="18px" h="18px" pl='3px' color={textColor}/>
              {/* TODO
              <Icon mt="4px" as={AiOutlineYoutube} w="18px" h="18px" pl='3px' color='black'/>
              <Icon mt="4px" as={BiLogoTiktok} w="18px" h="18px" pl='3px' color='black'/> */}
            </Flex>
          </MenuButton>
          <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="7px" bg={menuBg} border="none">
            <Flex mb="0px" flexDirection="column" pl={2} pt={1} pb={1}>
              <Checkbox isChecked={true} color={'black'} onChange={console.log("")} >
                <Flex flexDirection="row">
                  <Icon pt="2px" as={AiOutlineInstagram} w="20px" h="20px" pr='3px' color='black'/> 
                  <Text>Instagram</Text>
                </Flex>
              </Checkbox>
              {/* TODO
              <Checkbox isChecked={true} color={'black'} onChange={console.log("")} >
                <Flex flexDirection="row">
                  <Icon pt="2px" as={AiOutlineYoutube} w="20px" h="20px" pr='3px' color='black'/> 
                  <Text>Youtube</Text>
                </Flex>
              </Checkbox>
              <Checkbox isChecked={true} color={'black'} onChange={console.log("")} >
                <Flex flexDirection="row">
                  <Icon pt="2px" as={BiLogoTiktok} w="20px" h="20px" pr='3px' color='black'/> 
                  <Text>Tiktok</Text>
                </Flex>
              </Checkbox> */}
            </Flex>
          </MenuList>
        </Menu>      
    </Flex>
  );
}
