/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

import {
  Box,
} from "@chakra-ui/react";

import CampaignOverview from "views/admin/campaign/components/CampaignOverview";
import Campaign from "views/admin/campaign/components/Campaign";
import { fetchUtilViaGet } from '../../../components/fetch/FetchUtil.js';
import VideoPlayerModal from "views/admin/campaign/components/VideoPlayerModal";
import CircleLoader from "react-spinners/CircleLoader";

const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function Marketplace() {    
  const [modal, toggleModal] = React.useState(false);
  const [modalMedia, setModalMedia] = React.useState('');
  const [campaignResponse, setCampaignResponse] = React.useState('');
  const [campaignOverviewResponse, setCampaignOverviewResponse] = React.useState('');
  const [statu, setStatu] = React.useState(2);
  let [loading, setLoading] = React.useState(true);
  const [videoModalHeader, setVideoModalHeader] = React.useState('');
  const [videoModalUser, setVideoModalUser] = React.useState('');
  const [videoModalDate, setVideoModalDate] = React.useState('');
  const [videoModalHashtags, setVideoModalHashtags] = React.useState([]);

	React.useEffect(() => {
	  const getCampaignSummary = async () => {			
      try {
        const response = await fetchUtilViaGet('/campaignJoinBrand?status='+statu+'&brand_id='+localStorage.getItem('brand_id'));     
        setCampaignResponse(response);
      } catch (error) {
        console.error(error);
      }
	  };			
	  getCampaignSummary();
	}, [statu]);

  React.useEffect(() => {
	  const getCampaignOverview = async () => {			
      try {
        const response = await fetchUtilViaGet('/campaignOverview?brand_id='+localStorage.getItem('brand_id'));     
        setCampaignOverviewResponse(response);
        setLoading(false);   
      } catch (error) {
        console.error("campaignOverview:"+error);
      }
	  };			
	  getCampaignOverview();
	}, []);

  function closeModal() {
    toggleModal(false);
  }

  function updateStatus(status) {    
    setStatu(status);
  }

  return (
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "140px", md: "80px", xl: "80px" }}>      
      <CampaignOverview campaigns={campaignOverviewResponse[0]?.campaigns}
                        active={campaignOverviewResponse[0]?.active_campaigns}
                        passive={campaignOverviewResponse[0]?.passive_campaigns}
                        updateStatus={updateStatus}/>
      {[...Array(campaignResponse.length)].map((_, index) => (

        <Campaign brand={campaignResponse[index].brandName} 
                  campaignId={campaignResponse[index].campaignId}
                  campaignName={campaignResponse[index].campaignName} 
                  campaignStartDate={campaignResponse[index].start_date}
                  campaignEndDate={campaignResponse[index].end_date}
                  campignStatus = {campaignResponse[index].statu ? 'IN OPERATION' : 'ENDED'}
                  toggleModal={toggleModal} setModalMedia={setModalMedia}
                  setVideoModalHeader={setVideoModalHeader}
                  setVideoModalUser={setVideoModalUser}
                  setVideoModalDate={setVideoModalDate}
                  setVideoModalHashtags={setVideoModalHashtags}
                  />
      ))}
      <VideoPlayerModal modal={modal} 
                        closeModal={closeModal} 
                        modalMedia={modalMedia} 
                        videoModalHeader={videoModalHeader}
                        videoModalUser={videoModalUser}
                        videoModalDate={videoModalDate}
                        videoModalHashtags={videoModalHashtags}
                        />
    </Box>
  );
}
