/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Link,
  Text,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics2";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdYoutubeSearchedFor,
  MdAttachMoney,
  MdCampaign,
  MdFileCopy,
} from "react-icons/md";
import {AiOutlineYoutube,AiOutlineInstagram,AiOutlineAreaChart,AiOutlineVideoCamera,AiFillLike} from "react-icons/ai";
import {BsFillPersonLinesFill, BsInstagram, BsYoutube,} from "react-icons/bs";
import { BiLogoTiktok,BiLinkAlt} from "react-icons/bi";
import { LuHeartHandshake,} from "react-icons/lu";
import { TfiLayoutMediaLeftAlt,} from "react-icons/tfi";
import { IoEyeSharp,} from "react-icons/io5";
import { FaGreaterThan,} from "react-icons/fa";
import {columnsContentDistributionChartColumns,} from "views/admin/overview/variables/columnsData";
import tableDataTopCreators from "views/admin/overview/variables/tableDataTopCreators.json";
import { fetchUtilViaGet } from '../../../components/fetch/FetchUtil.js';
import { Redirect } from 'react-router-dom';
import CircleLoader from "react-spinners/CircleLoader";
import ProgressOverTime from "./components/ProgressOverTime2";

const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function UserReports(props) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  
  const { brandId } = props;
  const [campaignCount, setCampaignCount] = React.useState(0);
  const [influencerCount, setInfluencerCount] = React.useState(0);
  const [mediaCount, setMediaCount] = React.useState(0);
  const [impressions, setImpressions] = React.useState(0);
  const [reach, setReach] = React.useState(0);
  const [engagement, setEngagement] = React.useState(0);
  const [videoViews, setVideoViews] = React.useState(0);
  const [linkClicks, setLinkClicks] = React.useState(0);
  const [instagramReach, setInstagramReach] = React.useState(0);
  const [youtubeReach, setYoutubeReach] = React.useState(0);
  const [tiktokReach, setTiktokReach] = React.useState(0);
  const [instagramEngRate, setInstagramEngRate] = React.useState(0);
  const [youtubeEngRate, setYoutubeEngRate] = React.useState(0);
  const [tiktokEngRate, setTiktokEngRate] = React.useState(0);    
  let [loading, setLoading] = React.useState(true);  
  const sleep = ms => new Promise(r => setTimeout(r, ms));
  
  React.useEffect(() => {	  
	  getOverviewAndInsight();
	}, []);

  const getOverviewAndInsight = async () => {		
    await sleep(300)	
    try {        
      const response = await fetchUtilViaGet('/overviewAndInsight?brand_id='+localStorage.getItem('brand_id'));
      setCampaignCount(response[0].campaign_count);
      setInfluencerCount(response[0].influencer_count);
      setMediaCount(response[0].media_count);
      setImpressions(response[0].impressions);
      setReach(response[0].reach);
      setEngagement(response[0].engagement);
      setVideoViews(response[0].video_views);
      setLinkClicks(response[0].link_clicks);
      setInstagramReach(response[0].instagram_reach);
      setYoutubeReach(response[0].youtube_reach);
      setTiktokReach(response[0].tiktok_reach);
      setInstagramEngRate(response[0].instagram_eng_rate);
      setYoutubeEngRate(response[0].youtube_eng_rate);
      setTiktokEngRate(response[0].tiktok_eng_rate);     
      setLoading(false);   
    } catch (error) {
      console.error("OverviewAndInsight:" + error);
    }
  };			

  function formatNumber(number) {
    if (number >= 1e9) {
      // Billion or more
      return (number / 1e9).toFixed(1) + "B";
    } else if (number >= 1e6) {
      // Million or more
      return (number / 1e6).toFixed(1) + "M";
    } else if (number >= 1e3) {
      // Thousand or more
      return (number / 1e3).toFixed(1) + "K";
    } else if (number == null) {
      return "0";
    } else {
      return number;
    }
  }

  return (    
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>                   
      {!localStorage.getItem('authenticated') && <Redirect to="/auth" />}
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='10px'
        mb='20px'>
        <Link href="#/admin/campaign">
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdCampaign} color={brandColor} />
                }
              />
            }
            name='Campaigns'
            value={formatNumber(campaignCount)}
            endContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaGreaterThan} />
                }
              />
            }          
          />
        </Link>
        <Link href="#/admin/influencer">
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={BsFillPersonLinesFill} color={brandColor} />
                }
              />
            }
            name='Influencers'
            value={formatNumber(influencerCount)}
            endContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaGreaterThan} />
                }
              />
            }
          />
        </Link>
        <MiniStatistics 
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={TfiLayoutMediaLeftAlt} color='white' />}
            />
          }
          name='Content' 
          value={formatNumber(mediaCount)} />
        {/* TODO
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='28px' h='28px' as={BsYoutube} color={brandColor} />}
            />
          }
          name='Social Reach'
          value={formatNumber(youtubeReach)}
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='28px' h='28px' as={BsInstagram} color={brandColor} />}              
            />
          }
          name='Social Reach'
          value={formatNumber(instagramReach)}
        />
        {/* TODO
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={BiLogoTiktok} color={brandColor} />
              }
            />
          }
          name='Social Reach'
          value={formatNumber(tiktokReach)}
        /> */}
      </SimpleGrid>  
      <Text color={textColor} fontSize='2xl' ms='24px' mb='10px' fontWeight='700'>
        Insight
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='10px'
        mb='20px'>
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={IoEyeSharp} color={brandColor} />
              }
            />
          }
          name='Impressions'
          value={formatNumber(impressions)}
        />        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={AiOutlineAreaChart} color='white' />}
            />
          }
          name='Reach'
          value={formatNumber(reach)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={LuHeartHandshake} color='white' />}
            />
          }
          name='Engagements'
          value={formatNumber(engagement)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={AiOutlineVideoCamera} color={brandColor} />
              }
            />
          }
          name='Video Views'
          value={formatNumber(videoViews)}
        />
        <MiniStatistics 
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={BiLinkAlt} color='white' />}
            />
          }
          name='Link Clicks' 
          value={formatNumber(linkClicks)} />
        {/* TODO
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdYoutubeSearchedFor} color='white' />}
            />
          }
          name='ER Per Reach'
          value='???%'
        /> */}
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='28px' h='28px' as={BsYoutube} color={brandColor} />}
            />
          }
          name='Eng. Rate'
          value={formatNumber(youtubeEngRate) + '%'}
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={BsInstagram} color={brandColor} />
              }
            />
          }
          name='Eng. Rate'
          value={formatNumber(instagramEngRate) + '%'}
        />
      </SimpleGrid>  
      {/* TODO
      <ProgressOverTime />      
      <Text color={textColor} fontSize='2xl' ms='24px' mb='10px' fontWeight='700'>
        Progress Over Time
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TableContentDistributionChart
          columnsData={columnsContentDistributionChartColumns}
          tableData={tableDataTopCreators}
        />
      </SimpleGrid> */}
      {/* 
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
