import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import * as XLSX from 'xlsx';
import Campaign from 'views/admin/campaign/components/Campaign.js';
import { Calendar } from 'primereact/calendar';

export default function Table() {
    const [data, setData] = useState([]);    
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');    
    const [types] = useState(['VIDEO', 'STORY', 'POST', 'REELS']);
    const [channel] = useState(['INSTAGRAM', 'TIKTOK', 'YOUTUBE']);

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const formattedData = data.map(item => {
                const date = new Date(item.added_date);
                const formattedDate = date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                
                return {
                    InfluencerName: item.influencer_name,
                    Campaign: item.name,
                    Type: item.type,
                    Reach: item.reach,
                    Impression: item.impression,
                    Views: item.video_views,
                    Likes: item.like_count,
                    Comments: item.comment_count,
                    Clicks: item.link_clicks,
                    Save: item.save_count,
                    Engagement: item.engagement,
                    CPM: item.cpm,
                    CPV: item.cpv,
                    Budget: item.budget,                    
                    AddedDate: formattedDate
                };
            });
            const worksheet = xlsx.utils.json_to_sheet(formattedData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'influenceranalytics');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            influencer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },        
            type: { value: null, matchMode: FilterMatchMode.EQUALS },
            channel: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
        setGlobalFilterValue('');
    };

    function getData() {
        try {            
          const response = fetchUtilViaGet('/contentTable?user_id='+localStorage.getItem('user_id'));                        
          return response;
        } catch (error) {
          console.error("Content Data:" + error);
        }
    };			

    useEffect(() => {        
        getData().then((data) => {            
            setData(data);
            initFilters();
            setLoading(false);
        });
    }, []); 

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">                
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" style={{paddingLeft:"30px"}} />
                    <Button type="button" icon="pi pi-file-excel" severity="success" label='Download All Data' outlined onClick={exportExcel} data-pr-tooltip="XLS" style={{ width:'200px', height:'20px', paddingLeft:"20px", paddingRight:"30px" }} />
                </span>                
            </div>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.type}</span>
            </div>
        );
    };

    const typeItemTemplate = (option) => {
        return <Tag value={option} />;
    };

    const typeRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={types} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Select" className="p-column-filter" showClear style={{ minWidth: '8.5rem' }} />
        );
    };

    const channelBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.channel}</span>
            </div>
        );
    };

    const channelItemTemplate = (option) => {
        return <Tag value={option} />;
    };

    const channelRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={channel} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={channelItemTemplate} placeholder="Select" className="p-column-filter" showClear style={{ minWidth: '8.5rem' }} />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.added_date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    function formatNumber(number) {
        if (number >= 1e9) {
          // Billion or more
          return " " + (number / 1e9).toFixed(1) + " B";
        } else if (number >= 1e6) {
          // Million or more
          return " " + (number / 1e6).toFixed(1) + " M";
        } else if (number >= 1e3) {
          // Thousand or more
          return " " + (number / 1e3).toFixed(1) + " K";
        } else if (number == null) {
          return "0";
        } else {      
          return " " + number;
        }
    }

    const clearFilter = () => {
        initFilters();
    };

    const reachBodyTemplate = (rowData) => {
        return formatNumber(rowData.reach);
    };

    const impressionBodyTemplate = (rowData) => {
        return formatNumber(rowData.impression);
    };

    const videoViewsBodyTemplate = (rowData) => {
        return formatNumber(rowData.video_views);
    };

    const engagementBodyTemplate = (rowData) => {
        return formatNumber(rowData.engagement);
    };    

    const likeCountBodyTemplate = (rowData) => {
        return formatNumber(rowData.like_count);
    };

    const commentCountBodyTemplate = (rowData) => {
        return formatNumber(rowData.comment_count);
    };
    
    const linkClicksBodyTemplate = (rowData) => {
        return formatNumber(rowData.link_clicks);
    };
    
    const saveCountBodyTemplate = (rowData) => {
        return formatNumber(rowData.save_count);
    };

    const createDateBodyTemplate = (rowData) => {
        return formatDate(rowData.added_date);
    };

    const formatDate = (date) => {
        const originalDate = new Date(date);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-based
        const day = String(originalDate.getDate()).padStart(2, "0");    
        const formattedDateString = `${day}/${month}/${year}`;
        return formattedDateString;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <DataTable value={data} paginator rows={10} dataKey="id" filters={filters} filterDisplay="row" loading={loading}
                    globalFilterFields={['influencer_name', 'type', 'name']} header={header} emptyMessage="No content found." style={{ borderWidth: "0.5px" }}>
                <Column field="influencer_name" header="Influencer Name" filter filterMenuStyle={{ minWidth: '14rem' }} filterPlaceholder="Search by name" style={{ minWidth: '10rem', borderWidth: "0.5px" }} sortable/>
                <Column field="name" header="Campaign" filter filterMenuStyle={{ minWidth: '14rem' }} filterPlaceholder="Search by name" style={{ minWidth: '10rem', borderWidth: "0.5px" }} sortable/>
                <Column field="type" header="Type" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem', borderWidth: "0.5px" }} body={typeBodyTemplate} filter filterElement={typeRowFilterTemplate} sortable/>
                {/* <Column field="channel" header="Channel" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem', borderWidth: "0.5px" }} body={channelBodyTemplate} filter filterElement={channelRowFilterTemplate} sortable/> */}
                <Column field="reach" header="Reach" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={reachBodyTemplate}/>
                <Column field="impression" header="Impression" style={{ minWidth: '6rem', borderWidth: "0.5px" }} sortable body={impressionBodyTemplate}/>
                <Column field="video_views" header="Views" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={videoViewsBodyTemplate}/>
                <Column field="like_count" header="Likes" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={likeCountBodyTemplate}/>
                <Column field="comment_count" header="Comments" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={commentCountBodyTemplate}/>
                <Column field="link_clicks" header="Clicks" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={linkClicksBodyTemplate}/>
                <Column field="save_count" header="Save" style={{ minWidth: '5rem', borderWidth: "0.5px" }} sortable body={saveCountBodyTemplate}/>
                <Column field="engagement" header="Engagement" style={{ minWidth: '6rem', borderWidth: "0.5px" }} sortable body={engagementBodyTemplate}/>
                <Column field="cpm" header="CPM" style={{ minWidth: '4rem', borderWidth: "0.5px" }} sortable/>
                <Column field="cpv" header="CPV" style={{ minWidth: '4rem', borderWidth: "0.5px" }} sortable/>
                <Column field="budget" header="Budget" style={{ minWidth: '4rem', borderWidth: "0.5px" }} sortable/>
                <Column field="added_date" header="Add Date" filterField="added_date" dataType="date" style={{ minWidth: '4rem', borderWidth: "0.5px" }} sortable body={dateBodyTemplate} /*filter filterElement={dateFilterTemplate}*//>
            </DataTable>
        </div>
    );
}
        