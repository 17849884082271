import {
    Flex,
    Icon,    
    Button,    
    Select,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text
} from "@chakra-ui/react";
import React from "react";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import { fetchUtil } from '../../../../components/fetch/FetchUtil.js';
import CircleLoader from "react-spinners/CircleLoader";
import 'react-datepicker/dist/react-datepicker.css'

const override = {
    display: "block",
    marginLeft: "3%",
    marginTop: "1%",
    borderColor: "red",
};

const customStyles = {
    content: {
        margin: 'auto',
        width: '70%',
        maxHeight: '75%',
        borderRadius: '10px',
        paddingLeft: '50px',
        paddingRight: '50px',
        overflowY: 'auto',
    },
};

export default function UpdateContent(props) {
    const { modal, closeModal, updateId, detail, edit } = props;
    const textColor = useColorModeValue("navy.700", "white");
    
    const [loading, setLoading] = React.useState(false);
    const [likeCount, setLikeCount] = React.useState(0);
    const [commentCount, setCommentCount] = React.useState(0);
    const [saveCount, setSaveCount] = React.useState(0);
    const [shareCount, setShareCount] = React.useState(0);
    const [linkClicks, setLinkClicks] = React.useState(0);
    const [impression, setImpression] = React.useState(0);
    const [reach, setReach] = React.useState(0);
    const [videoViews, setVideoViews] = React.useState(0);
    const [storyLink, setStoryLink] = React.useState(null);
    const [followerCount, setFollowerCount] = React.useState(0);
    const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];
    const [budget, setBudget] = React.useState(0);
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [warmUp, setWarmUp] = React.useState(false);

    React.useEffect(() => {
        if (edit) {
            setLikeCount(detail.like_count);
            setCommentCount(detail.comment_count);
            setSaveCount(detail.save_count);
            setShareCount(detail.share_count);
            setLinkClicks(detail.link_clicks);
            setImpression(detail.impression);
            setReach(detail.reach);
            setVideoViews(detail.video_views);
            setStoryLink(detail.ig_story_links);
            setFollowerCount(detail.follower_count);
            setBudget(detail.budget);
        }
    }, [edit]);

    const updateInsight = async () => {   
        setWarmUp(false);
        if (budget === 0 || budget === null || budget === undefined) {
            setWarmUp(true);
            setLoading(false);
            return;
        }
        const engagement = likeCount + commentCount + saveCount + shareCount;
        let cpm = null;
        if (impression !== 0 && impression !== null && impression !== undefined) {
            cpm = Math.floor(budget / impression * 1000);
        }
        let cpv = null;
        if (videoViews !== 0 && videoViews !== null && videoViews !== undefined) {
            cpv = (budget / videoViews).toFixed(2);
        }        
        const request = JSON.stringify({            
            like_count: likeCount,
            comment_count: commentCount,
            save_count: saveCount,
            share_count: shareCount,
            impression: impression,
            engagement: engagement,
            reach: reach,
            video_views: videoViews,
            link_clicks: linkClicks,
            ig_story_links: storyLink,
            follower_count: followerCount,
            id: updateId,
            budget: budget,
            cpm: cpm,
            cpv: cpv
        });
        await fetchUtil('/updateInsight', request, 'json')
            .then((response) => {                
                setLoading(false);                                
                closeModal();
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            })
    };
    
    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}
            preventScroll={false}
        >
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='110%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Flex
                        direction='row'>
                        <Heading color={textColor} fontSize='24px' mb='4px'>
                            {edit ? 'Update Content' : 'Details'}
                        </Heading>
                        {warmUp && <Text ml={"20px"} mt={"5px"} color={"red"}>Please fill (*) fields then click "Update" button!</Text>}
                        <Button
                            h='35px'
                            position={"absolute"}
                            right={"70px"}
                            onClick={closeModal}>
                            <Icon as={MdCancel} w={5} h={5} />
                        </Button>
                    </Flex>
                </Box>
                <Flex
                    direction='row'>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "10px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Video Views
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Video Views'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setVideoViews(e.target.value)}
                                defaultValue={detail.video_views}
                                disabled={loading}
                                readOnly={!edit}
                            />      
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Like Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Like Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setLikeCount(Number(e.target.value))}
                                defaultValue={detail.like_count}
                                disabled={loading}
                                readOnly={!edit}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Comment Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Comment Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setCommentCount(Number(e.target.value))}
                                defaultValue={detail.comment_count}
                                disabled={loading}
                                readOnly={!edit}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Save Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Save Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setSaveCount(Number(e.target.value))}
                                defaultValue={detail.save_count}
                                disabled={loading}
                                readOnly={!edit}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Share Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Share Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setShareCount(Number(e.target.value))}
                                defaultValue={detail.share_count}
                                disabled={loading}
                                readOnly={!edit}
                            />                            
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Budget<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Budget'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setBudget(e.target.value)}
                                defaultValue={detail.budget}
                                disabled={loading}
                                readOnly={!edit}
                            />                                                   
                        </FormControl>                                             
                    </Flex>
                    <Flex mr={10}></Flex>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "10px", md: "auto" }}
                    >
                        <FormControl>                                                                                    
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Follower Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Follower Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setFollowerCount(e.target.value)}
                                defaultValue={detail.follower_count}
                                disabled={loading}
                                readOnly={!edit}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'
                                display='flex'>
                                Story Link
                            </FormLabel>
                            <Select
                                placeholder="Select Story Link"
                                isRequired={true}
                                onChange={(e) => {
                                    setStoryLink(e.target.value)
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading || !edit}
                                defaultValue={detail.ig_story_links}                                
                            >
                                <option value="trendyol.com">trendyol.com</option>
                                <option value="hepsiburada.com">hepsiburada.com</option>
                                <option value="philips.com.tr">philips.com.tr</option>
                                <option value="amazon.com.tr">amazon.com.tr</option>
                            </Select>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Impression
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Impression'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setImpression(e.target.value)}
                                defaultValue={detail.impression}
                                disabled={loading}
                                readOnly={!edit}
                            />                            
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Reach
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Reach'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setReach(e.target.value)}
                                defaultValue={detail.reach}
                                disabled={loading}
                                readOnly={!edit}
                            />               
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Link Clicks
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Link Clicks'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setLinkClicks(e.target.value)}
                                defaultValue={detail.link_clicks}
                                disabled={loading}
                                readOnly={!edit}
                            />                 
                            {edit ? 
                            <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'                                    
                                ml={6}                                    
                                onClick={updateInsight}
                                ms={{ base: "0px", md: "0px" }}                                    
                                size='lg'
                                width={"100%"}
                                mt={5}
                            >
                                Update
                                {loading ?
                                <CircleLoader
                                    color={"darkgray"}
                                    loading={loading}
                                    cssOverride={override}
                                    size={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : <></>}                                                                        
                            </Button>
                            : <></>}                                             
                        </FormControl>
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    );
}
