import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdContentPasteSearch,
} from "react-icons/md";
import {SiMarketo, SiPagespeedinsights} from "react-icons/si";
import {SlPeople} from "react-icons/sl";
import {GrOverview} from "react-icons/gr";
import MainDashboard from "views/admin/overview";
import Campaign from "views/admin/campaign";
import Profile from "views/admin/profile";
import Influencer from "views/admin/influencer";
import RTL from "views/admin/rtl";
import Progress from "views/admin/progress";
import Content from "views/admin/content";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Overview",
    layout: "/admin",
    path: "/overview/",
    icon: <Icon as={GrOverview} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Campaigns",
    layout: "/admin",
    path: "/campaign",
    icon: (
      <Icon
        as={SiMarketo}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Campaign,
    secondary: true,
  },
  {
    name: "Influencers",
    layout: "/admin",
    icon: <Icon as={SlPeople} width='20px' height='20px' color='inherit' />,
    path: "/influencer",
    component: Influencer,
  },
  {
    name: "Content",
    layout: "/admin",
    icon: <Icon as={MdContentPasteSearch} width='20px' height='20px' color='inherit' />,
    path: "/content",
    component: Content,
  },
  {
    name: "Progress",
    layout: "/admin",
    icon: <Icon as={SiPagespeedinsights} width='20px' height='20px' color='inherit' />,
    path: "/progress",
    component: Progress,
  },
  /*
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },  
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
  }, */
];

export default routes;
