export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "#",
    accessor: "#",
  },{
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "BRAND NAME",
    accessor: "brandname",
  }
  /* ,
  {
    Header: "DELETE",
    accessor: "delete",
  }, */
];

export const columnsContentDistributionChartColumns = [
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "COUNT",
    accessor: "count",
  }
];