// Chakra imports
import { Box, Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Line } from '@ant-design/charts';
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';

export default function PostConversationOverTimeChart(props) {
  const { ...rest } = props;
  
  let [data, setData] = React.useState([]);  

  React.useEffect(() => {
	  const conversationOverTime = async () => {			
      try {        
        const response = await fetchUtilViaGet('/conversationOverTime?type=4&type=2&type=1&brand_id='+localStorage.getItem('brand_id'));
        const sortedData = handleSort(response);
        const combinedDataReach = combineConsecutiveData(sortedData, "Reach");
        const combinedDataEngagement = combineConsecutiveData(sortedData, "Engagement");
        const combinedDataImpression = combineConsecutiveData(sortedData, "Impression");
        const combinedData = combinedDataReach.concat(combinedDataEngagement).concat(combinedDataImpression);
        setData(combinedData);
      } catch (error) {
        console.error("post conversationOverTime:" + error);
      }
	  };			
	  conversationOverTime();
	}, []);
  
  const combineConsecutiveData = (data, type) => {
    const result = [];
  
    data.filter((dt) => dt.type === type).forEach((entry, index) => {
      if (index > 0 ) {
        let entryValue = entry.value + result[result.length - 1].value;
        result.push({
          type: entry.type,
          date: entry.date,
          value: entryValue,
        });
      } else {
        result.push({
          type: entry.type,
          date: entry.date,
          value: entry.value,
        });
      }
    });
  
    return result;
  };
  
  const handleSort = (initialState) => {
    const sortedData = initialState.sort((a, b) => {
      const dateA = new Date(a.date.split('/').reverse().join('-'));
      const dateB = new Date(b.date.split('/').reverse().join('-'));
  
      if (dateA > dateB) {
        return 1;
      } else if (dateA < dateB) {
        return -1;
      } else {
        return 0;
      }
    });
  
    return sortedData;
  };

  function formatNumber(number) {
    if (number >= 1e9) {
      // Billion or more
      return (number / 1e9).toFixed(1) + "B";
    } else if (number >= 1e6) {
      // Million or more
      return (number / 1e6).toFixed(1) + "M";
    } else if (number >= 1e3) {
      // Thousand or more
      return (number / 1e3).toFixed(1) + "K";
    } else if (number == null) {
      return "0";
    } else {
      return number;
    }
  }

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    yAxis: {
      label: {
        formatter: (v) => formatNumber(v),
      },
    },
    legend: false,
    smooth: true,    
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex 
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}>
        <Text
          me='auto'
          color={textColor}
          fontSize='l'
          fontWeight='700'
          lineHeight='100%'          
          width={"100%"}
          mb={3}
          >
          POST CONVERSATION OVER TIME
        </Text>
        {config && <Line {...config} />}
    </Flex>    
  );
}
