/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from "@chakra-ui/react";
import React from "react";
import InfluencerOverview from "views/admin/influencer/components/InfluencerOverview";
import Influencer from "views/admin/influencer/components/Influencer";
import VideoPlayerModal from "views/admin/campaign/components/VideoPlayerModal";
import { fetchUtilViaGet } from '../../../components/fetch/FetchUtil.js';
import CircleLoader from "react-spinners/CircleLoader";

const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function Settings() {
  const [modal, toggleModal] = React.useState(false);
  const [modalMedia, setModalMedia] = React.useState('');
  const [influencerResponse, setInfluencerResponse] = React.useState('');
  const [influencerOverviewResponse, setInfluencerOverviewResponse] = React.useState('');
  const [filterMode, setFilterMode] = React.useState(0);
  let [loading, setLoading] = React.useState(true);
  const [videoModalHeader, setVideoModalHeader] = React.useState('');
  const [videoModalUser, setVideoModalUser] = React.useState('');
  const [videoModalDate, setVideoModalDate] = React.useState('');
  const [videoModalHashtags, setVideoModalHashtags] = React.useState([]);

  function closeModal() {
    toggleModal(false);
  }

  React.useEffect(() => {
	  const getInfluencerTotalDetail = async () => {			
      try {
        const response = await fetchUtilViaGet('/influencerTotalDetail?filterMode='+filterMode+'&brand_id='+localStorage.getItem('brand_id'));
        setInfluencerResponse(response);
      } catch (error) {
        console.error("influencerTotalDetail:"+error);
      }
	  };			
	  getInfluencerTotalDetail();
	}, [filterMode]);

  React.useEffect(() => {
	  const getInfluencerOverview = async () => {			
      try {
        const response = await fetchUtilViaGet('/influencerOverview?brand_id='+localStorage.getItem('brand_id'));             
        setInfluencerOverviewResponse(response);
        setLoading(false);   
      } catch (error) {
        console.error("influencerOverview:"+error);
      }
	  };			
	  getInfluencerOverview();
	}, []);

  function updateFilterMode(filterMode) {    
    setFilterMode(filterMode);
  }

  return (
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "140px", md: "80px", xl: "80px" }}>      
      <InfluencerOverview influencer_count={influencerOverviewResponse[0]?.influencer_count}
                          no_media={influencerOverviewResponse[0]?.no_media}
                          active={influencerOverviewResponse[0]?.active}
                          ended={influencerOverviewResponse[0]?.ended}
                          multi_campaign_count={influencerOverviewResponse[0]?.multi_campaign_count}
                          multi_brand_count={influencerOverviewResponse[0]?.multi_brand_count}
                          advocates={influencerOverviewResponse[0]?.advocates}
                          updateFilterMode={updateFilterMode}
      />
      {[...Array(influencerResponse.length)].map((_, index) => (
        <Influencer instagram_username={influencerResponse[index].instagram_username}
                    follower_count={influencerResponse[index].follower_count}
                    advocates={influencerResponse[index].advocates}
                    statu={influencerResponse[index].statu}
                    multi_campaign={influencerResponse[index].multi_campaign}
                    multi_brand={influencerResponse[index].multi_brand}
                    campaign_count={influencerResponse[index].campaign_count}
                    media_count={influencerResponse[index].media_count}
                    impression={influencerResponse[index].impression}
                    video_views={influencerResponse[index].video_views}
                    reach={influencerResponse[index].reach}
                    engagement={influencerResponse[index].engagement}
                    link_clicks={influencerResponse[index].link_clicks}
                    influencer_id={influencerResponse[index].id}
                    profil_picture_url={influencerResponse[index].profil_picture_url}
                    influencerCpm={influencerResponse[index].influencerCpm}
                    toggleModal={toggleModal}
                    setModalMedia={setModalMedia}
                    setVideoModalHeader={setVideoModalHeader}
                    setVideoModalUser={setVideoModalUser}
                    setVideoModalDate={setVideoModalDate}
                    setVideoModalHashtags={setVideoModalHashtags}
                  />
      ))} 
      <VideoPlayerModal modal={modal} closeModal={closeModal}
                        modalMedia={modalMedia} 
                        videoModalHeader={videoModalHeader}
                        videoModalUser={videoModalUser}
                        videoModalDate={videoModalDate}
                        videoModalHashtags={videoModalHashtags}/>     
    </Box>
  );
}
