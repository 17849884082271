import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import AddInfluencer from "views/super/influencer/components/AddInfluencer";

// Assets
import { MdCheckCircle, MdModeEdit, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';

export default function Users(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [user, setUser] = React.useState([]);
  const [updateId, setUpdateId] = React.useState(null);
  const [followerCount, setFollowerCount] = React.useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");  
  const [modal, toggleModal] = React.useState(false);
  const [updateMode, setUpdateMode] = React.useState(false);

  React.useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {			
    try {        
      const response = await fetchUtilViaGet('/influencer');     
      setUser(response);              
    } catch (error) {
      console.error(error);
    }
  };			
  
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 13;

  function closeModal() {    
    setUpdateId(null);
    setFollowerCount(null);    
    toggleModal(false);
    if (!updateMode) {
      getUsers();
    }    
    setUpdateMode(false);
  }

  function openModal() {
    toggleModal(true);
  }  

  const openDialog = (id, follower_count) => {
    setUpdateId(id);
    setFollowerCount(follower_count);
    setUpdateMode(true);
    openModal();
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Influencer List
        </Text>
        <Flex
          w={{ base: '100%', md: '80%', lg: '10%' }}>
          <Button
            fontSize='l'
            variant='brand'
            fontWeight='700'
            borderRadius={10}
            minWidth="90px"
            w='75%'
            h='35px'
            mb='10px'
            mr={3}
            onClick={openModal}>
              <Icon as={MdAdd} w={8} h={8} color={"white"} pr={2} />
              Add
          </Button>
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {user && user.map((row, rowIndex) => {
            return (
              <Tr key={rowIndex}>
                {columns.map((column, colIndex) => {
                  let data = '';
                  if (column.Header === '#') {
                    data = (
                      <Flex align='center'>                        
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {rowIndex+1}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'NAME') {
                    data = (
                      <Flex align='center'>                        
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.name}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'SURNAME') {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.surname}
                        </Text>
                      </Flex>
                    );
                  } else if (column.Header === 'USERNAME') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        @{row.instagram_username}
                      </Text>
                    );
                  } else if (column.Header === 'EDIT') {
                    data = (                      
                    <Button                      
                      h='35px'
                      onClick={() => openDialog(row.id, row.follower_count)}
                      >
                      <Icon as={MdModeEdit} w={5} h={5} />
                    </Button>
                    );
                  } 
                  return (
                    <Td                      
                      key={colIndex}
                      fontSize={{ sm: '14px' }}
                      maxH='30px !important'
                      py='8px'
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor='transparent'
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <AddInfluencer modal={modal} closeModal={closeModal} updateId={updateId} updateMode={updateMode} existfollowerCount={followerCount} />
    </Card>    
  );
}
