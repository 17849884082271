/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,  
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics2";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdYoutubeSearchedFor,
  MdAttachMoney,
  MdCampaign,
  MdFileCopy,
} from "react-icons/md";
import { fetchUtilViaGet } from '../../../components/fetch/FetchUtil.js';
import { Redirect, NavLink } from 'react-router-dom';
import CircleLoader from "react-spinners/CircleLoader";
import Brand from "views/super/brand/components/Brand";
import {columnsDataComplex,} from "views/super/brand/variables/columnsData";
import tableDataComplex from "views/super/user/variables/tableDataComplex.json";
const override = {
  display: "block",
  margin: "auto auto",
  marginTop: "25%",
  borderColor: "red",
};

export default function User(props) {  
  let [loading, setLoading] = React.useState(false);  

  return (    
    loading ?
      <CircleLoader
        color={"darkgray"}
        loading={loading}
        cssOverride={override}
        size={60}        
        aria-label="Loading Spinner"
        data-testid="loader"
      />: 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>                   
      {!localStorage.getItem('authenticated') && <Redirect to="/auth" />}
      <SimpleGrid
        mb='0px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>        
        <Brand
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </SimpleGrid>      
    </Box>
  );
}
