import {
    Flex,
    Icon,
    Text,
    Button,
    Checkbox,
    Select,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Center,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import Modal from 'react-modal';
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import { fetchUtil } from '../../../../components/fetch/FetchUtil.js';
import Upload from '../../../../components/s3/Upload.js';
import CircleLoader from "react-spinners/CircleLoader";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const override = {
    display: "block",
    marginLeft: "3%",
    marginTop: "1%",
    borderColor: "red",
};

const customStyles = {
    content: {
        margin: 'auto',
        width: '70%',
        maxHeight: '100%',
        borderRadius: '10px',
        paddingLeft: '50px',
        paddingRight: '50px',
        overflowY: 'auto',
    },
};

export default function AddContent(props) {
    const { modal, closeModal, setNewRecord } = props;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const brandColor = useColorModeValue("brand.500", "white");

    const [loading, setLoading] = React.useState(false);
    const [selectedChannel, setSelectedChannel] = React.useState('');
    const [selectedChannelName, setSelectedChannelName] = React.useState('');
    const [selectedType, setSelectedType] = React.useState('');
    const [selectedTypeName, setSelectedTypeName] = React.useState('Story');
    const [selectedInfluencer, setSelectedInfluencer] = React.useState('');
    const [selectedInfluencerName, setSelectedInfluencerName] = React.useState('');
    const [selectedCampaign, setSelectedCampaign] = React.useState('');
    const [selectedCampaignName, setSelectedCampaignName] = React.useState('');
    const [influencer, setInfluencer] = React.useState([]);
    const [campaign, setCampaign] = React.useState([]);
    const [likeCount, setLikeCount] = React.useState(0);
    const [commentCount, setCommentCount] = React.useState(0);
    const [saveCount, setSaveCount] = React.useState(0);
    const [shareCount, setShareCount] = React.useState(0);
    const [linkClicks, setLinkClicks] = React.useState(0);
    const [impression, setImpression] = React.useState(0);
    const [reach, setReach] = React.useState(0);
    const [videoViews, setVideoViews] = React.useState(0);
    const [url, setUrl] = React.useState("");
    const [thumbnailUrl, setThumbnailUrl] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [thumbnail, setThumbnail] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const [storyLink, setStoryLink] = React.useState(null);
    const [followerCount, setFollowerCount] = React.useState(0);
    const [contentFile, setContentFile] = React.useState(null);
    const [contentFileUrl, setContentFileUrl] = React.useState("");
    const [contentFile1, setContentFile1] = React.useState(null);
    const [contentFile1Url, setContentFile1Url] = React.useState("");
    const [contentFile2, setContentFile2] = React.useState(null);
    const [contentFile2Url, setContentFile2Url] = React.useState("");
    const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];
    const [budget, setBudget] = React.useState(0);
    const [warmUp, setWarmUp] = React.useState(false);

    React.useEffect(() => {
        const getInfluencerByUserId = async () => {
            try {
                const response = await fetchUtilViaGet('/influencerByUser?user_id=' + localStorage.getItem('user_id'));
                setInfluencer(response);
            } catch (error) {
                console.error(error);
            }
        };
        getInfluencerByUserId();
    }, []);

    React.useEffect(() => {
        const getCampaignByUserId = async () => {
            try {
                const response = await fetchUtilViaGet('/campaignByUser?user_id=' + localStorage.getItem('user_id'));
                setCampaign(response);
            } catch (error) {
                console.error(error);
            }
        };
        getCampaignByUserId();
    }, []);

    const addMedia = async () => {
        setWarmUp(false);
        if (loading || file == null || selectedChannel == '' || selectedInfluencer == '' || selectedCampaign == ''
            || budget === 0 ) {
            setWarmUp(true);
            setLoading(false);
            return;
        }
        uploadFile();
    };

    React.useEffect(() => {        
        if ((url && thumbnailUrl) || (url && selectedType == 2 && thumbnailUrl == "")) {
            addMediaContent();
        }
    }, [url, thumbnailUrl]);

    const addMediaContent = async () => {               
        const engagement = likeCount + commentCount + saveCount + shareCount;
        let cpm = null;
        if (impression !== 0 && impression !== null && impression !== undefined) {
            cpm = Math.floor(budget / impression * 1000);
        }
        let cpv = null;
        if (videoViews !== 0 && videoViews !== null && videoViews !== undefined) {
            cpv = (budget / videoViews).toFixed(2);
        }        
        const request = JSON.stringify({
            channel: selectedChannel,
            type: selectedType,
            influencer: selectedInfluencer,
            campaign: selectedCampaign,
            url: url,
            thumbnail_url: thumbnailUrl,
            like_count: likeCount,
            comment_count: commentCount,
            save_count: saveCount,
            share_count: shareCount,
            impression: impression,
            engagement: engagement,
            reach: reach,
            video_views: videoViews,
            link_clicks: linkClicks,
            added_date: formattedDate(date),
            content_url: contentFileUrl,
            content_url1: contentFile1Url,
            content_url2: contentFile2Url,
            ig_story_links: storyLink,
            follower_count: followerCount,
            budget: budget,
            cpm: cpm,
            cpv: cpv,
        });

        await fetchUtil('/addMedia', request, 'json')
            .then((response) => {                
                setLoading(false);
                const requestForContentScreen = JSON.stringify({
                    channel: selectedChannelName,
                    type: selectedTypeName,
                    influencer: selectedInfluencerName,
                    campaign: selectedCampaignName,
                    url: url,
                    thumbnail_url: thumbnailUrl,
                    like_count: likeCount,
                    comment_count: commentCount,
                    save_count: saveCount,
                    share_count: shareCount,
                    impression: impression,
                    engagement: engagement,
                    reach: reach,
                    video_views: videoViews,
                    link_clicks: linkClicks,
                    added_date: formattedDate(date),
                    content_url: contentFileUrl,
                    content_url1: contentFile1Url,
                    content_url2: contentFile2Url,
                    ig_story_links: storyLink,
                    follower_count: followerCount,
                    id: response.id,
                    budget: budget,
                    cpm: cpm,
                    cpv: cpv,
                });
                setNewRecord(requestForContentScreen);
                setUrl(null);
                setThumbnailUrl(null);
                setContentFileUrl(null);
                setContentFile1Url(null);
                setContentFile2Url(null);
                setFile(null);
                setThumbnail(null);
                setContentFile(null);
                setContentFile1(null);
                setContentFile2(null);
                closeModal();
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            })
    };

    function base64ToArrayBuffer(base64) {
        if (base64 == null) {
            return null;
        }
        const binaryString = window.atob(base64);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const uploadFile = async () => {        
        setLoading(true);
        const S3_BUCKET = "indashbucket"; // TODO
        const REGION = "us-east-1"; // TODO

        const mimeType = file.type;
        const parts = mimeType.split('/');
        const fileExtension = parts[1];
        const uui = uuidv4();
        const fileName = selectedTypeName + "_";
        const fileNameConcat = fileName + uui + "." + fileExtension;
        const thubnailNameConcat = "thumbnail_" + fileName + uui + ".jpg";
        const contentNameConcat = "content_" + fileName + uui + ".jpg";
        const contentNameConcat1 = "content1_" + fileName + uui + ".jpg";
        const contentNameConcat2 = "content2_" + fileName + uui + ".jpg";

        AWS.config.update({
            accessKeyId: "AKIA6A57N643ARJJLBFK", // TODO
            secretAccessKey: "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", // TODO
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const fileParams = {
            Bucket: S3_BUCKET,
            Key: fileNameConcat,
            Body: file,
            ContentType: file.type,
        };
        
        const base64Data = thumbnail != null ? thumbnail.replace(/^data:image\/\w+;base64,/, "") : null;
        const binaryData = base64ToArrayBuffer(base64Data);

        const thumbnailParams = {
            Bucket: S3_BUCKET,
            Key: thubnailNameConcat,
            Body: binaryData,
            ContentType: "image/jpeg",
        };

        const contentParams = {
            Bucket: S3_BUCKET,
            Key: contentNameConcat,
            Body: contentFile,
            ContentType: "image/jpeg",
        };

        const contentParams1= {
            Bucket: S3_BUCKET,
            Key: contentNameConcat1,
            Body: contentFile1,
            ContentType: "image/jpeg",
        };

        const contentParams2= {
            Bucket: S3_BUCKET,
            Key: contentNameConcat2,
            Body: contentFile2,
            ContentType: "image/jpeg",
        };

        const uploadFilePromise = s3.putObject(fileParams).on("httpUploadProgress", (evt) => {
            console.log(
                "Uploading File" + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
        }).promise();
        const uploadThumbnailPromise = (selectedType != 2) ? 
            s3.putObject(thumbnailParams).on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading Thumbnail" + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            }).promise() : null;
        const uploadContentFilePromise = contentFile != null ? s3.putObject(contentParams).on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading File" + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            }).promise() : null;
        const uploadContentFilePromise1 = contentFile1 != null ? s3.putObject(contentParams1).on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading File" + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            }).promise() : null;
        const uploadContentFilePromise2 = contentFile1 != null ? s3.putObject(contentParams2).on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading File" + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            }).promise() : null;
        const upload = contentFile == null ? 
                        (selectedType == 2) ? Promise.all([uploadFilePromise]) : 
                                            Promise.all([uploadFilePromise, uploadThumbnailPromise]) :
                        (selectedType == 2) ? Promise.all([uploadFilePromise, uploadContentFilePromise, uploadContentFilePromise1, uploadContentFilePromise2]) : 
                                            Promise.all([uploadFilePromise, uploadThumbnailPromise, uploadContentFilePromise, uploadContentFilePromise1, uploadContentFilePromise2]);

        await upload.then((err, data) => {
            setUrl("https://indashbucket.s3.amazonaws.com/" + fileNameConcat) // TODO
            if (contentFile != null) {
                setContentFileUrl("https://indashbucket.s3.amazonaws.com/" + contentNameConcat); // TODO
            }
            if (contentFile1 != null) {
                setContentFile1Url("https://indashbucket.s3.amazonaws.com/" + contentNameConcat1); // TODO
            }
            if (contentFile2 != null) {
                setContentFile2Url("https://indashbucket.s3.amazonaws.com/" + contentNameConcat2); // TODO
            }
            if ((selectedType != 2)) {
                setThumbnailUrl("https://indashbucket.s3.amazonaws.com/" + thubnailNameConcat); // TODO                
            }                        
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        });
    };

    function formattedDate(date) {        
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }

    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}
            preventScroll={false}
        >
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='110%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Flex direction='row'>
                        <Heading color={textColor} fontSize='24px' mb='4px'>
                            Add Content
                        </Heading>
                        {warmUp && <Text ml={"20px"} mt={"5px"} color={"red"}>Please fill (*) fields then click "Add" button!</Text>}
                        <Button
                            h='35px'
                            position={"absolute"}
                            right={"70px"}
                            onClick={closeModal}>
                            <Icon as={MdCancel} w={5} h={5} />
                        </Button>
                    </Flex>
                </Box>
                <Flex
                    direction='row'>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "10px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Channel<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Select
                                placeholder="Select Channel"
                                isRequired={true}
                                onChange={(e) => {
                                    setSelectedChannel(e.target.value)
                                    setSelectedChannelName(e.target.options[e.target.selectedIndex].text);
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                <option value='1'>Instagram</option>
                                <option value='2'>YouTube</option>
                                <option value='3'>TikTok</option>
                            </Select>                            
                        </FormControl>
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Video Views
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Video Views'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setVideoViews(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />      
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Like Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Like Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setLikeCount(Number(e.target.value))}
                                defaultValue={0}
                                disabled={loading}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Comment Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Comment Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setCommentCount(Number(e.target.value))}
                                defaultValue={0}
                                disabled={loading}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Save Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Save Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setSaveCount(Number(e.target.value))}
                                defaultValue={0}
                                disabled={loading}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Share Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Share Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setShareCount(Number(e.target.value))}
                                defaultValue={0}
                                disabled={loading}
                            />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Link Clicks
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Link Clicks'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setLinkClicks(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />               
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Upload Statistic File<Text color={brandStars}>*</Text> <Text fontSize={"smaller"}>(Max 3 files)</Text>
                            </FormLabel>
                            <Upload thumbnail={thumbnail} setFile={setContentFile} setFile1={setContentFile1} setFile2={setContentFile2} contentFile={true} disabled={loading} selectedType={2} />                                                         
                            <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='1px'>
                                    Added Date<Text color={brandStars}>*</Text>
                                </FormLabel>
                            <Box borderRadius={14}  mb='8px' borderWidth={1} p='9px' pl='15px' w="100%">                                
                                <DatePicker
                                    showTimeSelect
                                    selected={date}
                                    onChange={date => setDate(date)}                            
                                    dateFormat="dd/MM/yyyy hh:mm"
                                    disabled={loading}
                                    
                                />
                            </Box>               
                        </FormControl>                                             
                    </Flex>
                    <Flex mr={10}></Flex>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "10px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Influencer<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Select
                                placeholder="Select Influencer"
                                isRequired={true}
                                onChange={(e) => {
                                    setSelectedInfluencer(e.target.value)
                                    setSelectedInfluencerName(e.target.options[e.target.selectedIndex].text);
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                {
                                    influencer && influencer.map((item, index) => (
                                        <option value={item.id}>{item.name} {item.surname} - {item.instagram_username}</option>
                                    ))
                                }
                            </Select>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'
                                display='flex'>
                                Type<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Select
                                placeholder="Select Type"
                                isRequired={true}
                                onChange={(e) => {
                                    setSelectedType(e.target.value);
                                    setSelectedTypeName(e.target.options[e.target.selectedIndex].text);
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                <option value='1'>Video</option>
                                <option value='2'>Post</option>
                                <option value='3'>Story</option>
                                <option value='4'>Reels</option>
                            </Select>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'
                                display='flex'>
                                Campaign<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Select
                                placeholder="Select Campaign"
                                isRequired={true}
                                onChange={(e) => {
                                    setSelectedCampaign(e.target.value)
                                    setSelectedCampaignName(e.target.options[e.target.selectedIndex].text);
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                {
                                    campaign && campaign.map((item, index) => (
                                        <option value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Select>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Follower Count
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Follower Count'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setFollowerCount(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'
                                display='flex'>
                                Story Link
                            </FormLabel>
                            <Select
                                placeholder="Select Story Link"
                                isRequired={true}
                                onChange={(e) => {
                                    setStoryLink(e.target.value)
                                }}
                                fontSize='sm'
                                mb='8px'
                                size='lg'
                                variant='auth'
                                disabled={loading}
                            >
                                <option value="trendyol.com">trendyol.com</option>
                                <option value="hepsiburada.com">hepsiburada.com</option>
                                <option value="philips.com.tr">philips.com.tr</option>
                                <option value="amazon.com.tr">amazon.com.tr</option>
                            </Select>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Impression
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Impression'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setImpression(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />                            
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Reach
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Reach'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setReach(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />                      
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Upload Content File<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Upload thumbnail={thumbnail} setFile={setFile} setThumbnail={setThumbnail} contentFile={false} disabled={loading} selectedType={selectedType} />                            
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='1px'>
                                Budget<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                placeholder='Enter Budget'
                                mb='8px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setBudget(e.target.value)}
                                defaultValue={0}
                                disabled={loading}
                            />              
                            <Button
                                    fontSize='sm'
                                    variant='brand'
                                    fontWeight='500'                                    
                                    ml={6}                                    
                                    onClick={addMedia}
                                    ms={{ base: "0px", md: "0px" }}                                    
                                    size='lg'
                                    width={"100%"}
                                    mt={5}
                                >
                                    Add
                                    {loading ?
                                    <CircleLoader
                                        color={"darkgray"}
                                        loading={loading}
                                        cssOverride={override}
                                        size={30}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    /> : <></>}                                                                        
                                </Button>
                        </FormControl>
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    );
}
