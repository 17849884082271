import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Icon
} from "@chakra-ui/react";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";

const customStyles = {
  content: {
    top: '400px',
    left: 'auto',
    right: '-180px',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '380px',
    maxHeight: '700px',
    borderRadius: '10px',
    padding: '0px',
    overflowY: 'auto',
  },
};
export default function CampaignOverview(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const { modal, closeModal, modalMedia, videoModalHeader, videoModalUser, videoModalDate } = props;

  const formatDate = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-based
    const day = String(originalDate.getDate()).padStart(2, "0");
    const hours = String(originalDate.getHours()).padStart(2, "0");
    const minutes = String(originalDate.getMinutes()).padStart(2, "0");
    const formattedDateTimeString = `${day}/${month}/${year} - ${hours}:${minutes}`;
    return formattedDateTimeString;
  };

  return (
    <Modal
      isOpen={modal}
      onRequestClose={closeModal}
      style={customStyles}
      appElement={document.getElementById('root')}
      contentLabel="Example Modal"
      preventScroll={false}>
      <Flex
        px='22px'
        pb='10px'
        pt='10px'
        mb='10px'
        backgroundColor='white'
        borderRadius={10}
        flexDirection="column" >
        <Flex
          pb='10px'
          direction='row'>
          <Text color='black' borderRadius={8} fontSize='l' fontWeight='700' height='22px'>
            {videoModalHeader}
          </Text>
          <Button
            h='22px'
            ml={140}
            onClick={closeModal}>
            <Icon as={MdCancel} w={5} h={5} color={'red'}/>
          </Button>
        </Flex>        
        <Flex
          backgroundColor='rgba(229, 231, 235, 0.6)'
          p='10px'
          borderRadius={10}
          flexDirection="column">
          <Flex>
            <Text color='black' borderRadius={8} fontSize='sm' fontWeight='700' height='22px'>
              {videoModalUser}
            </Text>
            <Flex
              marginLeft='auto'
              marginRight='10px'>
              <Text color='black' paddingRight='1' fontSize='sm' fontWeight='600' >
                {formatDate(videoModalDate)}
              </Text>
            </Flex>
          </Flex>
          <Flex
            pt='10px'>
            <iframe
              title="Instagram Post"
              src={modalMedia}
              width="315"
              height="600"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
            ></iframe>
          </Flex>
          <Flex
            w='320px'
            p={1}
            flexWrap="wrap">
            <Text color='white' backgroundColor='rgba(223,36,76,0.8)' borderRadius={8} paddingLeft='3' paddingRight='3' fontSize='sm' fontWeight='700' m={1}>
              #işbirliği
            </Text>
            <Text color='white' backgroundColor='rgba(223,36,76,0.8)' borderRadius={8} paddingLeft='3' paddingRight='3' fontSize='sm' fontWeight='700' m={1}>
              @philipsevurunleriturkiye
            </Text>
            <Text color='white' backgroundColor='rgba(223,36,76,0.8)' borderRadius={8} paddingLeft='3' paddingRight='3' fontSize='sm' fontWeight='700' m={1}>
              @philipsevurunleriturkiye
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
