// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";

export default function Media(props) {
  const { toggleModal, setModalMedia, image, setVideoModalHeader, setVideoModalUser, setVideoModalDate, setVideoModalHashtags, row } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  return (
    <Card p='6px'>
      <Flex direction={{ base: "column" }} justify='center'>
        <Box mb={{ base: "5px", "2xl": "10px" }} position='relative' h='120px' w='70px'>          
          <Image
              src={image}
              w={{ base: "100%", "3xl": "100%" }}
              h={{ base: "100%", "3xl": "100%" }}
              borderRadius='10px'
              onClick={() => {
                toggleModal(true);                
                setModalMedia(row.url);
                setVideoModalHeader(row.channel + " " + row.type);
                setVideoModalUser(row.influencer);
                setVideoModalDate(row.added_date);
                //setVideoModalHashtags(row.hashtags);
              }}
            />          
        </Box>        
      </Flex>
    </Card>
  );
}
