import React, { useState } from 'react';
import './Tooltip.css'; // Make sure to create this CSS file

function Tooltip({ children, text }) {
  const [show, setShow] = useState(false);

  return (
    <div 
      className="tooltip-container" 
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && <div className="tooltip-box">{text.toLocaleString()}</div>}
    </div>
  );
}

export default Tooltip;