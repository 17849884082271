import {
    Flex,
    Icon,
    Text,
    Button,
    Select,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Switch,
    InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import Modal from 'react-modal';
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPlusOne, MdAdd } from "react-icons/md";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fetchUtilViaGet } from '../../../../components/fetch/FetchUtil.js';
import { fetchUtil } from '../../../../components/fetch/FetchUtil.js';
import CircleLoader from "react-spinners/CircleLoader";
import Upload from '../../../../components/s3/Upload.js';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

const override = {
    display: "block",
    marginLeft: "3%",
    marginTop: "1%",
    borderColor: "red",
};

export default function AddInfluencer(props) {
    const { modal, closeModal, updateMode, updateId, existfollowerCount } = props;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [date, setDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date(Date.now() + 90 * 24 * 60 * 60 * 1000));
    const [loading, setLoading] = React.useState(false);
    const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);    
    const [campaigns, setCampaigns] = React.useState([]);
    const [campaignName, setCampaignName] = React.useState('');
    const [addButtonDisabled, setAddButtonDisabled] = React.useState(false);
    const [name, setName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [instagramUsername, setInstagramUsername] = React.useState('');
    const [tiktokUsername, setTiktokUsername] = React.useState('');
    const [youtubeUsername, setYoutubeUsername] = React.useState('');
    const [followerCount, setFollowerCount] = React.useState('');
    const [advocates, setAdvocates] = React.useState(false);
    const [profilPictureUrl, setProfilPictureUrl] = React.useState('');
    const [thumbnail, setThumbnail] = React.useState(null);
    const [contentFile, setContentFile] = React.useState(null);
    const [contentFile1, setContentFile1] = React.useState(null);
    const [contentFile2, setContentFile2] = React.useState(null);
    const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];
    const [campaignInfluencer, setCampaignInfluencer] = React.useState([]);

    const customStyles = {
        content: {
            margin: 'auto',
            width: '70%',
            maxHeight: updateMode ? '60%' : '80%',
            borderRadius: '10px',
            padding: '50px',
            overflowY: 'auto',
        },
    };    

    React.useEffect(() => {
        const getCampaigns = async () => {
            try {
                const response = await fetchUtilViaGet('/campaign?user_id=' + localStorage.getItem('user_id'));                
                if (updateMode) {            
                    const responseCampaignInfluencer = await fetchUtilViaGet('/campaignInfluencer?influencer_id=' + updateId);
                    setCampaignInfluencer(responseCampaignInfluencer);
                    setFollowerCount(existfollowerCount);
                }                        
                setCampaigns(response);
            } catch (error) {
                console.error(error);
            }
        };
        if (modal) {
            getCampaigns();
        }        
    }, [modal]);

    const handleSelectChange = (event) => {
        const selectedOptions = Array.from(event.target.options)
                                    .filter(option => option.selected)
                                    .map(option => option.value);
        setSelectedCampaigns(selectedOptions);
    };

    const addInfluencer = async () => {
        setLoading(true);
        const request = JSON.stringify({            
            name: name,
            surname: surname,
            instagram_username: instagramUsername,
            tiktok_username: tiktokUsername,
            youtube_username: youtubeUsername,
            follower_count: followerCount,
            profil_picture_url: profilPictureUrl,
            selected_campaign_ids: selectedCampaigns,
            advocates: advocates ? 1 : 0,
            statu: '1'
        });
        await fetchUtil('/addInfluencer', request, 'json')
            .then((response) => {
                setLoading(false);
                setAddButtonDisabled(false);
                setProfilPictureUrl('');
                setContentFile(null);
                setContentFile1(null);
                setContentFile2(null);
                setAdvocates(false);
                setFollowerCount('');
                setInstagramUsername('');
                setTiktokUsername('');
                setYoutubeUsername('');
                setName('');
                setSurname('');
                setCampaignName('');
                setSelectedCampaigns([]);
                setThumbnail(null);                
                closeModal();
            }).catch((error) => {
                console.log(error);
                setAddButtonDisabled(false);
                setLoading(false);
            })
    };

    const updateInfo = async () => {
        setLoading(true);        
        const request = JSON.stringify({                        
            follower_count: followerCount,            
            selected_campaign_ids: selectedCampaigns,
            influencer_id: updateId,
        });
        await fetchUtil('/updateInfluencer', request, 'json')
            .then((response) => {
                setLoading(false);
                setAddButtonDisabled(false);
                setFollowerCount('');
                setSelectedCampaigns([]);
                closeModal();
            }).catch((error) => {
                console.log(error);
                setAddButtonDisabled(false);
                setLoading(false);
            })
    };

    React.useEffect(() => {
        if (updateMode) {
            if (selectedCampaigns.length != 0) {
                setAddButtonDisabled(false);
            } else {
                setAddButtonDisabled(true);
            }        
        } else {
            if (selectedCampaigns.length != 0 && name !== '' && surname !== '' && instagramUsername !== '' && followerCount) {
                setAddButtonDisabled(false);
            } else {
                setAddButtonDisabled(true);
            }        
        }
    }, [selectedCampaigns, name, surname, instagramUsername, followerCount]);

    React.useEffect(() => {        
        if (profilPictureUrl) {
            addInfluencer();
        }
    }, [profilPictureUrl]);

    const uploadFile = async () => {        
        setLoading(true);
        const S3_BUCKET = "indashbucket"; // TODO
        const REGION = "us-east-1"; // TODO

        const mimeType = contentFile.type;
        const parts = mimeType.split('/');
        const fileExtension = parts[1];
        const uui = uuidv4();
        const fileName = "profil_";
        const fileNameConcat = fileName + uui + "." + fileExtension;
        
        AWS.config.update({
            accessKeyId: "AKIA6A57N643ARJJLBFK", // TODO
            secretAccessKey: "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", // TODO
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const fileParams = {
            Bucket: S3_BUCKET,
            Key: fileNameConcat,
            Body: contentFile,
            ContentType: contentFile.type,
        };

        const uploadFilePromise = s3.putObject(fileParams).on("httpUploadProgress", (evt) => {
            console.log(
                "Uploading File" + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
        }).promise();
        
        const upload = Promise.all([uploadFilePromise]);

        await upload.then((err, data) => {
            setProfilPictureUrl("https://indashbucket.s3.amazonaws.com/" + fileNameConcat) // TODO
            console.log(profilPictureUrl);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        });
    };

    const getCampaignNamesByInfluencerId = (influencerId) => {
        if (campaignInfluencer.length === 0) {
          return '';
        }      
        const matchingRecords = campaignInfluencer
          .filter(record => record.influencer_id === influencerId)
          .map(record => {
            const matchingCampaign = campaigns.find(campaign => campaign.id === record.campaign_id);
            return matchingCampaign ? matchingCampaign.name : null;
          });      
        const nonNullCampaigns = matchingRecords.filter(name => name !== null);      
        return nonNullCampaigns.join(', ');
    };
      
    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}
            preventScroll={false}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Flex
                        direction='row'>
                        <Heading color={textColor} fontSize='36px' mb='10px'>
                            {updateMode ? "Update" : "Add Influencer"}
                        </Heading>
                        <Button
                            h='35px'
                            ml={600}
                            mt={-30}
                            onClick={closeModal}>
                            <Icon as={MdCancel} w={5} h={5} />
                        </Button>
                    </Flex>
                </Box>
                <Flex
                    direction='row'
                    mb={"24px"}>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}          >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Campaign<Text color={brandStars}>*</Text><Text fontSize={"smaller"}>(Multiple Choise)</Text>
                            </FormLabel>
                            <Select
                                isRequired={true}
                                onChange={handleSelectChange}
                                fontSize='sm'
                                mb='24px'
                                size='lg'
                                variant='auth'                                
                                disabled={loading}
                                height={"150px"}
                                multiple
                            >
                                {
                                    updateMode ? 
                                    campaigns && campaigns.filter(campaign => 
                                        campaignInfluencer.filter(record => record.influencer_id === updateId).map(record => record.campaign_id)
                                        .includes(campaign.id) === false
                                        ).map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                    :
                                    campaigns && campaigns.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Select>
                            {updateMode && <Text fontSize={"sm"}>Existing Campaigns : {getCampaignNamesByInfluencerId(updateId)}</Text>}
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'
                                hidden={updateMode}
                                >
                                Advocates<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Switch
                                isChecked={advocates}                                
                                variant="main"
                                colorScheme="brandScheme"
                                size="lg"
                                mb='30px'
                                disabled={loading}
                                hidden={updateMode}
                                onChange={() => setAdvocates(!advocates)}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                disabled={loading}
                                display='flex'
                                hidden={updateMode}
                                >
                                Profil Picture<Text color={brandStars}>*</Text>
                            </FormLabel>
                            {!updateMode && 
                            <Upload thumbnail={thumbnail} setFile={setContentFile} setFile1={setContentFile1} setFile2={setContentFile2} contentFile={true} disabled={loading} selectedType={2} />
                            }
                        </FormControl>
                    </Flex>
                    <Flex mr={10}></Flex>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}
                    >
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Follower Counts<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='number'
                                placeholder='Follower Count'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                onChange={(e) => setFollowerCount(e.target.value)}
                                disabled={loading}
                                value={updateMode ? existfollowerCount : followerCount}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'
                                hidden={updateMode}
                                >
                                Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder='Name'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setName(e.target.value)}
                                disabled={loading}
                                hidden={updateMode}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'
                                hidden={updateMode}
                                >
                                Surname<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder='Surname'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setSurname(e.target.value)}
                                disabled={loading}
                                hidden={updateMode}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'
                                hidden={updateMode}
                                >
                                Instagram Username<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder='Username'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                onChange={(e) => setInstagramUsername(e.target.value)}
                                disabled={loading}
                                hidden={updateMode}
                            />
                            <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50px'
                                mt={'20px'}
                                mb='24px'
                                left='auto'
                                _hover={{ bg: "brand.500" }}
                                onClick={updateMode ? updateInfo : uploadFile}
                                disabled={addButtonDisabled}                                
                                >
                                {updateMode ? "Update" : "Add"}
                                {loading ?
                                <CircleLoader
                                    color={"darkgray"}
                                    loading={loading}
                                    cssOverride={override}
                                    size={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : <></>}     
                            </Button>
                        </FormControl>
                    </Flex>
                </Flex>                
            </Flex>
        </Modal>
    );
}
